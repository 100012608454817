import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosGet, axiosPost, getError } from "utils/axios-api.util";
import { ticket, updateTicket } from "../../config/server";

const initialState = {
  ticket_list: [],
  loading: true,
  error: null,

  updates_message: [],
  fetch_update_loading: true,
  u_loading: false,

  updating_success_msg: null,
  updating_error_msg: null,
};

export const fetching_tickets = createAsyncThunk(
  "fetching-tickets",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosGet(ticket, token);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      throw errMsg;
    }
  }
);

export const updates_tickets = createAsyncThunk(
  "update-tickets",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosGet(ticket, token);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      throw errMsg;
    }
  }
);

export const adding_ticket = createAsyncThunk(
  "adding-tickets",
  async (data, thunkApi) => {
    const { ticketText, title } = data;
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const config = {
        ticketText: ticketText,
        title: title,
      };
      const res = await axiosPost(ticket, config, token);
      thunkApi.dispatch(updates_tickets());
      toast.success(res.data.message);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      toast.error(err.res.data.message);
      throw errMsg;
    }
  }
);

export const fetch_updates_tickets_msg = createAsyncThunk(
  "fetch_update_tickets_msg",
  async (id, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosGet(updateTicket(id), token);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      throw errMsg;
    }
  }
);

export const updates_tickets_msg = createAsyncThunk(
  "update_tickets_msg",
  async (id, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosGet(updateTicket(id), token);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      throw errMsg;
    }
  }
);

export const updating_tickets = createAsyncThunk(
  "updating_tickets",
  async (data, thunkApi) => {
    const { comments, id } = data;
    const config = {
      comments: comments,
    };
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosPost(updateTicket(id), config, token);
      thunkApi.dispatch(updates_tickets_msg(id));
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      throw errMsg;
    }
  }
);

const tickets = createSlice({
  name: "tickets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetching_tickets.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetching_tickets.fulfilled, (state, action) => {
        state.ticket_list = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetching_tickets.rejected, (state, action) => {
        state.ticket_list = [];
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updates_tickets.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updates_tickets.fulfilled, (state, action) => {
        state.ticket_list = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(updates_tickets.rejected, (state, action) => {
        state.ticket_list = [];
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetch_updates_tickets_msg.pending, (state, action) => {
        state.fetch_update_loading = true;
      })
      .addCase(fetch_updates_tickets_msg.fulfilled, (state, action) => {
        state.updates_message = action.payload;
        state.fetch_update_loading = false;
        state.error = null;
      })
      .addCase(fetch_updates_tickets_msg.rejected, (state, action) => {
        state.updates_message = [];
        state.fetch_update_loading = false;
        state.error = action.error.message;
      })
      .addCase(updates_tickets_msg.pending, (state, action) => {
        state.fetch_update_loading = true;
      })
      .addCase(updates_tickets_msg.fulfilled, (state, action) => {
        state.updates_message = action.payload;
        state.fetch_update_loading = false;
        state.error = null;
      })
      .addCase(updates_tickets_msg.rejected, (state, action) => {
        state.updates_message = [];
        state.fetch_update_loading = false;
        state.error = action.error.message;
      })
      .addCase(updating_tickets.pending, (state, action) => {
        state.u_loading = true;
      })
      .addCase(updating_tickets.fulfilled, (state, action) => {
        state.updating_success_msg = action.payload;
        state.u_loading = false;
        state.updating_error_msg = null;
      })
      .addCase(updating_tickets.rejected, (state, action) => {
        state.updating_success_msg = [];
        state.u_loading = false;
        state.updating_error_msg = action.error.message;
      });
  },
});

export default tickets;
