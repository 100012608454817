// const dev_domain = "https://dev-admin.zybisys.com";
// const uat_domain = "https://uat-admin.zybisys.com";
// const prod_domain = "https://cloud-admin.zybisys.com";
import { domain } from "config/server";

//before deploying to any of server first change domain then build react app for deployment:
// export const domain = dev_domain;

//=========== DASHBOARD =============
export const root = {
  check_login: `${domain}/users/check-login`,
};
//=================== CLOUD CONNECT ====================
export const cloudConnect = {
  overview: {
    all: (datacenter) =>
      `${domain}/cloud-connect/overview?datacenter=${datacenter}&version=2`,
    message_per_sec: (brokerId, linkType, ip) =>
      `${domain}/cloud-connect/overview/msg-per-sec?broker_id=${brokerId}&link_type=${linkType}&ip=${ip}`,
    latency: (brokerId, linkType, ip) =>
      `${domain}/cloud-connect/overview/latency?broker_id=${brokerId}&link_type=${linkType}&ip=${ip}`,
    segment_login: (brokerId, linkType, ip) =>
      `${domain}/cloud-connect/overview/login?broker_id=${brokerId}&link_type=${linkType}`,
  },
  validation: {
    router_ip: `${domain}/cloud-connect/check-router-ip `,
    mac_address: `${domain}/cloud-connect/get-router-mac`,
    broker_id: `${domain}/cloud-connect/check-brokerid`,
  },
  nsc: {
    latency: (segment, from, to, ip) =>
      `${domain}/cloud-connect/nse-${segment}-msg-latency?from=${from}&to=${to}&ip=${ip}`,
    message_per_sec: (segment, from, to, ip) =>
      `${domain}/cloud-connect/nse-${segment}-msg-per-sec?from=${from}&to=${to}&ip=${ip}`,
  },
  bse: {
    latency: (segment, from, to, ip) =>
      `${domain}/cloud-connect/bse-${segment}-msg-latency?ip=${ip}&from=${from}&to=${to}`,
    message_per_sec: (segment, from, to, ip) =>
      `${domain}/cloud-connect/bse-${segment}-msg-per-sec?ip=${ip}&from=${from}&to=${to}`,
  },
  mcx: {
    latency: (segment, from, to, ip) =>
      `${domain}/cloud-connect/mcx-${segment}-msg-latency?ip=${ip}&from=${from}&to=${to}`,
    message_per_sec: (segment, from, to, ip) =>
      `${domain}/cloud-connect/mcx-${segment}-msg-per-sec?ip=${ip}&from=${from}&to=${to}`,
  },
  router: {
    all: (datacenter) =>
      `${domain}/users/cloud-connect?datacenter=${datacenter}`,
    add: `${domain}/cloud-connect`,
    update: {
      paired: (cid) => `${domain}/cloud-connect/pair/${cid}`,
      unpaired: (cid) => `${domain}/cloud-connect/${cid}`,
      ip: (id) => `${domain}/cloud-connect/check-router-ip/${id} `,
    },
    delete: (cid, datacenter) =>
      `${domain}/users/cloud-connect/${cid}?datacenter=${datacenter}`,
  },
  logs: {
    exchange_links: (datacenter, linkType, date) =>
      `${domain}/cloud-connect/logs/app/${linkType}?datacenter=${datacenter}&date=${date}`,
    exchange_links_Log: (datacenter, linkType, from, to) =>
      `${domain}/cloud-connect/logs/app/${linkType}?datacenter=${datacenter}&from=${from}&to=${to}`,
    router_pair: (pairname, from, to) =>
      `${domain}/cloud-connect/logs/network/${pairname}?from=${from}&to=${to}`,
  },
  pair: (datacenter) =>
    `${domain}/users/cloud-connect-pair?datacenter=${datacenter}`,
  unpair: (id, datacenter) =>
    `${domain}/users/cloud-connect-unpair/${id}?datacenter=${datacenter}`,
  bandwidth: (data) =>
    `${domain}/users/counter_item_mac/${data.mac}/${data.from}/${data.to}?datacenter=${data.configDatacenter}&purpose=${data.purpose}`,
  internal_host: (data) =>
    `${domain}/users/cloud-connect-internalhost/${data.ip}/${data.from}/${data.to}?datacenter=${data.datacenter}&purpose=${data.purpose}`,
  multicast: (ip, from, to, datacenter, purpose) =>
    `${domain}/users/cloud-connect-multicast/${ip}/${from}/${to}?datacenter=${datacenter}&purpose=${purpose}`,
  master: (datacenter, protocol) =>
    `${domain}/cloud-connect/overview/network?datacenter=${datacenter}&protocol=${protocol}`,
  recent_log: (datacenter, date, logType) =>
    `${domain}/cloud-connect/logs/app/${logType}?datacenter=${datacenter}&date=${date}`,
  service: (ip) => `${domain}/cloud-connect/${ip}/services`,
};
