import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "components/features/UI/Inputs";
import axios from "axios";
import { ADDRESS_INFO } from "config/server";
import React, { useState } from "react";
import { CiWarning } from "react-icons/ci";
import { toastUpdateSuccess } from "utils/toast.util";
import Validator from "utils/validator";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { HelperText } from "./SignUp";

const DISCLAIMER_TEXT = [
  {
    title: "",
    desc: "This disclaimer governs your use of our Software as a Service (SaaS) application. By using our application, you accept this disclaimer in full. If you disagree with any part of this disclaimer, you must not use our application.",
    fooetr: "",
  },
  {
    title: "No Warranty",
    desc: "Our SaaS application is provided 'as is' without any representations or warranties, express or implied. We make no representations or warranties in relation to this application or the information and materials provided within it.",
    fooetr: "",
  },
  {
    title: "No Liability",
    desc: "In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this application.",
    fooetr: "",
  },
  {
    title: "Third-Party Links",
    desc: "Our application may contain links to third-party websites which are not under our control. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. ",
    fooetr: "",
  },
  {
    title: "Usage Restrictions",
    desc: "You must not use our application in any way that causes, or may cause, damage to the application or impairment of the availability or accessibility of the application; or in any way which is unlawful, illegal, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.",
    fooetr: "",
  },
  {
    title: "Modification and Termination",
    desc: "We reserve the right to modify or discontinue, temporarily or permanently, the application or any features or portions thereof without prior notice. We may also terminate or suspend your access to the application at any time, for any reason.",
    fooetr: "",
  },
  {
    title: "Changes to Disclaimer",
    desc: "We may revise this disclaimer from time to time. The revised disclaimer will apply to the use of our application from the date of the publication of the revised disclaimer on our application.",
    fooetr:
      "By using our SaaS application, you agree to the terms of this disclaimer. If you do not agree with these terms, please refrain from using our application.",
  },
];

export default function CompleteProfile({ data }) {
  const theme = useTheme();
  const [profileData, setProfileData] = useState({
    fname: { value: "", error: null },
    lname: { value: "", error: null },
    companyName: { value: "", error: null },
    country: { value: "", error: null },
    state: { value: "", error: null },
    pincode: { value: "", error: null },
  });
  const [profileSatus, setProfilStatus] = useState({
    loading: false,
    error: false,
    data: "",
  });

  const [checkToggle, setCheckToggle] = useState(false);
  const [agree, setAgree] = useState(false);
  const [expand, setExpand] = useState(false);

  const onClose = () => {
    setCheckToggle(false);
  };
  const history = useHistory();

  const handleScroll = (event) => {
    let { scrollHeight, scrollTop, clientHeight } = event.target;
    const totalScrollRemains = Math.floor(
      scrollHeight - scrollTop - clientHeight
    );

    if (totalScrollRemains <= 1) {
      setCheckToggle(true);
    }
  };

  function checkValidation(type, value) {
    const validate = new Validator(value);
    if (type === "fname") {
      return !validate.newName();
    }
    if (type === "companyName") {
      return !validate.company();
    }
    if (type === "lname") {
      return !validate.newLastName();
    }
    if (type === "country") {
      return !validate.newName();
    }
    if (type === "state") {
      return !validate.newName();
    }
    if (type === "pincode") {
      return !validate.pincode();
    }
  }

  function handleInputChange(e) {
    const { value, name } = e.target;
    const validity = checkValidation(name, value);
    setProfileData((prevValue) => ({
      ...prevValue,
      [name]: { value, error: validity },
    }));
  }
  async function postAddress(info) {
    const toast_id = "Submiting otp...";
    setProfilStatus((pre) => {
      return {
        ...pre,
        loading: true,
      };
    });
    try {
      let res = await axios.post(ADDRESS_INFO, info);
      setProfilStatus((pre) => {
        return {
          ...pre,
          loading: false,
          data: res.data.message,
        };
      });

      history.push("/login");
      toastUpdateSuccess(toast_id, "Created Successfully");
    } catch (err) {
      setProfilStatus((pre) => {
        return {
          ...pre,
          loading: false,
          error: err.response.data.message,
        };
      });

      toast.error(err.response.data.message);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    let info = {
      id: data?.message?.userId,
      name: `${profileData.fname.value} ${profileData.lname.value}`,
      company_name: profileData.companyName.value,
      country: profileData.country.value,
      state: profileData.state.value,
      pincode: profileData.pincode.value,
    };

    if (data?.message?.userId) {
      postAddress(info);
    }
    onClose();
  }
  const handleCheck = (e) => {
    const { checked } = e.target;
    setAgree(checked);
  };

  return (
    <>
      <Box
        sx={{
          width: "100vw ",
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "22px",
        }}
      >
        <Typography
          fullwidth
          component={"h2"}
          sx={{
            fontSize: "24px",
            fontWeight: "600",
            lineHeight: "37px",
            letterSpacing: "0.1px",
            textAlign: "center",
          }}
        >
          Complete Your Profile
        </Typography>
        <Box
          component={"p"}
          sx={{
            width: "60%",
            maxWidth: "800px",
            fontSize: "14px",
            padding: "12px",
            lineHeight: "21px",
            color: "#8D8B8B",
            textAlign: "center",
            marginBottom: "22px",
          }}
        >
          Let's make your experience seamless. Kindly provide the required
          details to personalize your account and access tailored features. Your
          information remains confidential and enhances your journey with us.
        </Box>

        <Box sx={{ minWidth: "471px" }}>
          <Box
            sx={{
              width: "471px",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "12px",
              bgcolor: "red",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}
            >
              <TextField
                error={profileData.fname.error}
                value={profileData.fname.value.trimStart()}
                id="outlined-basic"
                name="fname"
                onChange={handleInputChange}
                required
                label="First Name"
                variant="outlined"
                helperText={
                  profileData.fname.error ? (
                    <>
                      <HelperText
                        errMsg={"Please enter valid name:"}
                        details={[" character should be between 3 to 30"]}
                      />
                    </>
                  ) : (
                    ""
                  )
                }
              />
              <TextField
                error={profileData.lname.error}
                value={profileData.lname.value.trimStart()}
                id="outlined-basic"
                name="lname"
                onChange={handleInputChange}
                required
                label="Last Name"
                variant="outlined"
                helperText={
                  profileData.lname.error ? (
                    <>
                      <HelperText
                        errMsg={"Please enter valid name:"}
                        details={[" character should be between 1 to 30"]}
                      />
                    </>
                  ) : (
                    ""
                  )
                }
              />
            </Box>

            <TextField
              error={profileData.companyName.error}
              value={profileData.companyName.value.trimStart()}
              id="outlined-basic"
              name="companyName"
              onChange={handleInputChange}
              required
              label="Company Name"
              sx={{ width: "100%" }}
              variant="outlined"
              helperText={
                profileData.companyName.error ? (
                  <>
                    <HelperText
                      errMsg={"Please enter valid company name."}
                      details={[" character should be 3 or more than 3 "]}
                    />
                  </>
                ) : (
                  ""
                )
              }
            />

            <Box
              sx={{
                width: "471px",
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "red",
                gap: 1,
              }}
            >
              <TextField
                id="outlined-basic"
                error={profileData.country.error}
                value={profileData.country.value.trimStart()}
                name="country"
                onChange={handleInputChange}
                required
                label="Country"
                variant="outlined"
                helperText={
                  profileData.country.error ? (
                    <>
                      <HelperText
                        errMsg={"Invalid char.."}
                        details={[" character should be between 3 to 30"]}
                      />
                    </>
                  ) : (
                    ""
                  )
                }
              />
              <TextField
                id="outlined-basic"
                error={profileData.state.error}
                value={profileData.state.value.trimStart()}
                name="state"
                onChange={handleInputChange}
                required
                label="State"
                variant="outlined"
                helperText={
                  profileData.state.error ? (
                    <>
                      <HelperText
                        errMsg={"Invalid char.."}
                        details={[" character should be between 3 to 30"]}
                      />
                    </>
                  ) : (
                    ""
                  )
                }
              />
              <TextField
                id="outlined-basic"
                error={profileData.pincode.error}
                value={profileData.pincode.value.trimStart()}
                name="pincode"
                onChange={handleInputChange}
                required
                label="Pin Code"
                variant="outlined"
                helperText={
                  profileData.pincode.error ? (
                    <>
                      <HelperText
                        errMsg={"Invalid pincode"}
                        details={[
                          " Pincode should be 6 digits",
                          "it should not start with zero",
                        ]}
                      />
                    </>
                  ) : (
                    ""
                  )
                }
              />
            </Box>

            <Box
              onScroll={handleScroll}
              sx={{
                width: "100%",
                height: "130px",
                bgcolor: "#e1f5fe",
                borderColor: "#e0e0e0",
                overflow: "auto",
                padding: "2px 6px",
                fontSize: "14px",
              }}
            >
              <Typography sx={{ textAlign: "center" }}>Disclaimer</Typography>
              <Disclaimer
                data={!expand ? DISCLAIMER_TEXT.slice(0, 1) : DISCLAIMER_TEXT}
                expand={expand}
                setExpand={setExpand}
                theme={theme}
              />
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  required
                  disabled={!checkToggle || !expand}
                  control={<Checkbox />}
                  onChange={(e) => handleCheck(e)}
                  label={
                    <Box
                      component={"p"}
                      sx={{
                        fontSize: "15px",
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      I have read and I agree
                    </Box>
                  }
                />
              </FormGroup>
            </Box>
            <Button
              loading={profileSatus.loading}
              disabled={
                profileData.fname.error ||
                !profileData.fname.value ||
                profileData.lname.error ||
                !profileData.lname.value ||
                profileData.country.error ||
                !profileData.country.value ||
                profileData.state.error ||
                !profileData.state.value ||
                profileData.pincode.error ||
                !profileData.pincode.value ||
                !agree
              }
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

const Disclaimer = ({ data, expand, setExpand, theme }) => {
  return (
    <>
      <Box>
        {data.map((val, i) => {
          return (
            <>
              <Box component={"p"} sx={{ fontSize: "14px", fontWeight: "500" }}>
                {val.title}
              </Box>
              <Box component={"span"} variant="body2" gutterBottom>
                {val.desc}
              </Box>
              <Box
                component={"span"}
                sx={{ fontWeight: "500", fontSize: "15px" }}
                variant="body2"
                gutterBottom
              >
                {val.footer}
              </Box>
            </>
          );
        })}
        <Box
          onClick={() => setExpand(true)}
          component={"span"}
          sx={{
            color: theme.palette.blue[400],
            marginLeft: "3px",
            cursor: "pointer",
            fontWeight: "400",
          }}
        >
          {expand ? "" : "more..."}
        </Box>
      </Box>
    </>
  );
};
