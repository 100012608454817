import React, { useEffect, useState } from "react";
import moment from "moment";
import { MenuItem, Select } from "components/features/UI/Inputs";
import { Box, Button, Typography, useTheme } from "@mui/material";
import DateTime from "./date-time";
import { FIRST_MAY, LAST_MAY, THRESHOLD_DATE } from "utils/conversion";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const INTERVAL_MENU = [
  "15 mins",
  "30 mins",
  "1 hr",
  "2 hrs",
  "Today",
  "Yesterday",
  "Last 1 Week",
  "Custom",
];

function getTimeRange(type) {
  switch (type) {
    case "15 mins":
      return { from: moment().subtract(15, "minutes"), to: moment() };
    case "30 mins":
      return { from: moment().subtract(30, "minutes"), to: moment() };
    case "1 hr":
      return { from: moment().subtract(1, "hour"), to: moment() };
    case "2 hrs":
      return { from: moment().subtract(2, "hours"), to: moment() };
    case "Today":
      return {
        from: moment().startOf("day"),
        to: moment(),
      };
    case "Yesterday":
      return {
        from: moment().subtract(1, "day").startOf("day"),
        to: moment().subtract(1, "day").endOf("day"),
      };
    case "Last 1 Week":
      return {
        from: moment().subtract(6, "days").startOf("day"),
        to: moment().endOf("day"),
      };
    case "Last 1 Month":
      return {
        from: moment().startOf("month"),
        to: moment().endOf("month"),
      };
    case "7 days":
      return {
        from: moment().subtract(7, "day").startOf("day"),
        to: moment(),
      };
    case "30 days":
      return {
        from: moment().subtract(30, "day").startOf("day"),
        to: moment(),
      };
    case "3 months":
      return {
        from: moment().subtract(3, "months").startOf("day"),
        to: moment(),
      };
    default:
      return { from: moment().subtract(15, "minutes"), to: moment() };
  }
}

const DEFAULT_TIME = moment();

/**
 *
 * @param props {Object}  property of Component
 * @param props.onSelect {Function} return {from(in millisec(in millisec)),to}
 * @param props.from {number} in millisec
 * @param props.to {number} in millisec
 * @returns DateTimeRange picker with from & to input
 *
 * from - to : time should always have 15min diff for initial values as this will set only initial value
 */

function convertToTimestamp(dateStr) {
  var dateObj = new Date(dateStr);
  var unixTimestampMs = dateObj.getTime();
  var timestampStr = unixTimestampMs.toString();
  return Number(timestampStr);
}

function checkDate(date, date2) {
  let day = new Date(date).getDay();
  let months = new Date(date).getMonth();
  let year = new Date(date).getFullYear();
  let day2 = new Date(date2).getDay();
  let months2 = new Date(date2).getMonth();
  let year2 = new Date(date2).getFullYear();

  if (day === day2 && months === months2 && year2 === year) {
    return true;
  } else {
    return false;
  }
}

function changeToProperDate() {
  let today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  return month < 10 ? `${year}0${month}${day}` : `${year}${month}${day}`;
}

function DateTimeRange({
  onSelect,
  from,
  to,
  intervals,
  interval,
  onDataChange,
  hide,
  newDate,
}) {
  const theme = useTheme();
  const defaultInterval =
    intervals && intervals?.length ? intervals?.at(0) : INTERVAL_MENU?.at(0);
  const [selectedInterval, setSelectedInterval] = useState(defaultInterval);
  const [startDate, setStartDate] = useState(from);
  const [endDate, setEndDate] = useState(to);
  const [validDate, setValideDate] = useState();
  const location = useLocation();

  function handleIntervalSelect(e) {
    const { value } = e.target;
    setSelectedInterval(value);

    if (value !== "Custom") {
      const selectedValue = getTimeRange(value);

      setStartDate(selectedValue?.from?._d?.getTime() ?? 0);
      setEndDate(selectedValue?.to?._d?.getTime() ?? 0);
      onSelect({
        from: selectedValue?.from?._d?.getTime(),
        to: selectedValue?.to?._d?.getTime(),
        type: value,
      });
    }
  }

  useEffect(() => {
    if (newDate && selectedInterval === "Custom") {
      onDataChange({ from: startDate, to: endDate, type: selectedInterval });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, selectedInterval]);

  function handleFromDateTimeSelect(date) {
    setStartDate(date);
  }
  function handleToDateTimeSelect(date) {
    setEndDate(date);
  }

  function handleSubmit() {
    onSelect({ from: startDate, to: endDate, type: selectedInterval });
  }

  useEffect(() => {
    if (checkDate(FIRST_MAY, startDate)) {
      setValideDate(changeToProperDate());
    } else if (FIRST_MAY > startDate) {
      setValideDate(LAST_MAY);
    } else {
      setValideDate(changeToProperDate());
    }
  }, [startDate]);

  useEffect(() => {
    let date = new Date(THRESHOLD_DATE).getTime();
    let current_Date = new Date().getTime();

    if (selectedInterval === "Custom") {
      const fromDate = new Date(startDate).getMonth() + 1;
      const toDate = new Date(endDate).getMonth() + 1;
      if (location.pathname.split("/")[3] !== "lama") {
        setEndDate(
          FIRST_MAY <= startDate && fromDate !== toDate
            ? current_Date
            : fromDate === toDate
            ? endDate
            : date
        );
      }
    } else {
      if (!endDate) {
        setEndDate(current_Date);
      }
    }
  }, [startDate]);

  useEffect(() => {
    if (interval) {
      setSelectedInterval(interval);
      const selectedValue = getTimeRange(interval);
      setStartDate(from);
      setEndDate(to);
      onSelect({
        from: selectedValue?.from?._d?.getTime() ?? 0,
        to: selectedValue?.to?._d?.getTime() ?? 0,
        type: interval,
      });
    }
  }, [interval]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "fit-content",
        gap: 2,
        alignItems: "center",
      }}
    >
      {Boolean(selectedInterval === "Custom") && (
        <Box>
          <Box sx={{ mr: 3, display: "flex", alignItems: "center", gap: 2 }}>
            <DateTime
              value={startDate}
              onChange={handleFromDateTimeSelect}
              label="From"
              error={startDate > endDate}
              disableFuture={true}
            />
            <DateTime
              value={endDate}
              onChange={handleToDateTimeSelect}
              label="To"
              disableFuture={true}
              maxCurrentYear={moment(validDate, "YYYYMMDD")}
              min={moment(startDate)}
            />
            {hide ? (
              ""
            ) : (
              <Button
                size="small"
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  startDate > endDate ||
                  startDate < endDate - 31 * 24 * 60 * 60 * 1000
                }
              >
                Submit
              </Button>
            )}
          </Box>
          {Boolean(
            startDate > endDate ||
              startDate < endDate - 31 * 24 * 60 * 60 * 1000
          ) && (
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.error.main,
                fontSize: "11px",
                marginTop: "4px",
              }}
            >
              {startDate < endDate - 32 * 24 * 60 * 60 * 1000
                ? "Your date range should be less than or equal to 30 days"
                : '"To" DateTime must be greater than or equal to the "FROM " DateTime.'}
            </Typography>
          )}
        </Box>
      )}
      <Select
        id="zoom-view-internet-activity-interval"
        labelId="zoom-view-interval-label"
        label="Interval"
        value={selectedInterval}
        onChange={handleIntervalSelect}
      >
        {intervals?.length
          ? intervals.map((interval, i) => (
              <MenuItem
                key={`${interval}--${i}--interval-menu`}
                value={interval}
              >
                {interval}
              </MenuItem>
            ))
          : INTERVAL_MENU.map((interval, i) => (
              <MenuItem
                key={`${interval}--${i}--interval-menu`}
                value={interval}
              >
                {interval}
              </MenuItem>
            ))}
      </Select>
    </Box>
  );
}

export default DateTimeRange;
