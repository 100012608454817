import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloudConnect } from "utils/server-api";
import { axiosGet, getError } from "utils/axios-api.util";
import { logout } from "../actions";

//================ INITIAL STATE ================
const initialState = {
  overview: {
    isLoading: false,
    isFetching: false,
    data: [],
    isError: false,
    error: "",
  },
  routers: {
    isLoading: false,
    data: [],
    isError: false,
    error: "",
  },
};

//============================== THUNK ========================

//------------------- FETCH OVERVIEW ----------------
export const fetchOverview = createAsyncThunk(
  "overview",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const datacenter = state.datacenters?.selected?.u_location;
      const res = await axiosGet(cloudConnect.overview.all(datacenter), token);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(errMsg);
      throw errMsg;
    }
  }
);

//------------------ FETCH ROUTERS ----------------------
export const fetchRouters = createAsyncThunk("routers", async (_, thunkApi) => {
  try {
    const state = thunkApi.getState();
    const token = state.user?.token;
    const datacenter = state.datacenters?.selected?.u_location;
    const res = await axiosGet(cloudConnect.router.all(datacenter), token);
    return res.data.message;
  } catch (err) {
    const errMsg = getError(err, thunkApi.dispatch);
    thunkApi.rejectWithValue(errMsg);
    throw errMsg;
  }
});

//==================== SLICES ==================
const cloudConnectSlice = createSlice({
  name: "cloudConnect",
  initialState,
  extraReducers: (builder) => {
    //================= WHEN LOGGED OUT ===========
    builder.addCase("LOGOUT", (state, action) => {
      return initialState;
    });

    //-------------------- Overview ----------------
    builder.addCase(fetchOverview.pending, (state, action) => {
      return {
        ...state,
        overview: {
          ...state.overview,
          data: [],
          isLoading: true,
          isFetching: true,
          isError: false,
          error: "",
        },
      };
    });
    builder.addCase(fetchOverview.fulfilled, (state, action) => {
      return {
        ...state,
        overview: {
          ...state.overview,
          isFetching: false,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    builder.addCase(fetchOverview.rejected, (state, action) => {
      const error = action.payload
        ? action.payload.errorMessage
        : action.error.message;

      return {
        ...state,
        overview: {
          ...state.overview,
          data: [],
          isLoading: false,
          isError: true,
          error,
        },
      };
    });

    //---------------- router ---------------------
    builder.addCase(fetchRouters.pending, (state, action) => {
      return {
        ...state,
        routers: {
          ...state.routers,
          isLoading: true,
          isError: false,
          error: "",
        },
      };
    });
    builder.addCase(fetchRouters.fulfilled, (state, action) => {
      return {
        ...state,
        routers: {
          ...state.routers,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    builder.addCase(fetchRouters.rejected, (state, action) => {
      const error = action.payload
        ? action.payload.errorMessage
        : action.error.message;

      return {
        ...state,
        routers: {
          ...state.routers,
          data: [],
          isLoading: false,
          isError: true,
          error,
        },
      };
    });
    builder.addCase(logout.toString(), (state, action) => {
      return { ...initialState };
    });
  },
});

export default cloudConnectSlice;
