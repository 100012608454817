import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Router, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { lazy } from "@loadable/component";
import {
  Auth,
  ForgotPassword,
  ResetPassword,
  Logout,
  PageNotFound,
  SetPassword,
  VerifyEmail,
} from "pages";
import * as paths from "config/route-paths";
import SignUp from "pages/signup/SignUp";
import { useLocation } from "react-router-dom";

const CustomerDashboard = lazy(() => import("./pages/routes/Private-routes"));

const App = () => {
  const history = useHistory();
  return (
    <>
      <Router history={history}>
        <Suspense
          fallback={
            <div id="loader_screen">
              <div className="loader"></div>
              <div className="loader-2"></div>
            </div>
          }
        >
          <Switch>
            <Route path={paths.LOGIN} component={RestrictLogin} />
            <Route path={paths.FORGOT_PASSWORD} component={ForgotPassword} />
            <Route path={paths.RESET_PASSWORD} component={ResetPassword} />
            <Route path={paths.LOGOUT} component={Logout} />
            <Route path={paths.LAMA_SIGNUP} component={SignUp} />

            <Route
              path={`${paths.SET_PASSWORD}/:token`}
              component={SetPassword}
            />
            <Route
              path={`${paths.VERIFY_EMAIL}/:token`}
              component={VerifyEmail}
            />
            <Route exact path="/" component={RedirectOnHome} />

            <PrivateRoute path={paths.COC}>
              <CustomerDashboard />
            </PrivateRoute>
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Suspense>
      </Router>
      <ToastContainer
        style={{ fontSize: "0.8rem" }}
        position="bottom-right"
        theme="colored"
      />
    </>
  );
};

//Auth Routers
const PrivateRoute = ({ children, ...rest }) => {
  const isAuthenticated = useSelector((state) => Boolean(state.user.token));

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const RedirectOnHome = () => {
  const isAuthenticated = useSelector((state) => Boolean(state.user.token));

  return isAuthenticated ? (
    <Redirect to={{ pathname: "/coc/dashboard" }} />
  ) : (
    <Redirect to={{ pathname: "/login" }} />
  );
};

const RestrictLogin = () => {
  const isAuthenticated = useSelector((state) => Boolean(state.user.token));
  const { pathname } = useLocation();

  return isAuthenticated ? (
    <Redirect to={{ pathname: "/coc/dashboard" }} />
  ) : pathname === "/login" ? (
    <>
      <Auth />
    </>
  ) : (
    <PageNotFound />
  );
};

export default App;
