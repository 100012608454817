import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import "react-toastify/dist/ReactToastify.css";
import "styles/css/style.css";
import "rc-datetime-picker/dist/picker.css";
import { theme } from "utils/theme.util";
import App from "./App";
import { store, persistor } from "store/index";

const onBeforeLift = () => {};

const app = (
  <Provider store={store}>
    <PersistGate
      loading={<h1>Loading...</h1>}
      onBeforeLift={onBeforeLift}
      persistor={persistor}
    >
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StyledEngineProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
