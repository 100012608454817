import moment from "moment";
import { useEffect, useRef, useState } from "react";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import { Box, Slider, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";

/**
 *
 * @param props {object}  property of component
 * @param props.value {number} date in millisecond
 * @param props.onChange {functn} Triggers when input changes and return time in millisec
 * @returns
 */

function DateTime({
  onChange,
  value,
  label,
  min,
  max,
  error,
  disableFuture,
  minCurrentYear,
  maxCurrentYear,
}) {
  const elRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(moment());
  const [isCurrentYear, setIsCurrentYear] = useState("");

  function handleTime(e, newValue, name) {
    const now = date.clone();

    if (name === "hr") {
      now.set("hours", newValue);
    }
    if (name === "min") {
      now.set("minutes", newValue);
    }
    now.set("seconds", 0);

    setDate(now);
    onChange(now._d.getTime());
  }

  useEffect(() => {
    if (value) {
      setDate(moment(value));
    }
  }, [value]);
  //closing calendar display on clicking else where
  useEffect(() => {
    function handleListener(e) {
      if (!elRef?.current?.contains(e.target)) {
        setShowCalendar(false);
      }
    }
    document.addEventListener("click", handleListener);
    return () => document.removeEventListener("click", handleListener);
  }, []);
  useEffect(() => {
    let years = new Date(value).getFullYear();
    setIsCurrentYear(years);
  }, [value]);

  return (
    <Box
      sx={{ position: "relative", width: "fit-content", zIndex: 40 }}
      ref={elRef}
    >
      <TextField
        label={label}
        value={date.format("DD-MM-yyyy HH:mm")}
        onClick={() => setShowCalendar((prevValue) => !prevValue)}
        readOnly
        size="small"
        type={error ? "error" : ""}
      />
      {Boolean(showCalendar) && (
        <Box
          sx={{
            position: "absolute",
            background: "#fff",
            top: 26,
            zIndex: 40,
            filter:
              "drop-shadow(0 10px 8px rgb(0 0 0 / 0.03)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.03))",
            mt: 2,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              minHeight: "330px",
              border: `1px solid ${grey[100]}`,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                disableFuture={disableFuture ? disableFuture : false}
                minDate={
                  (minCurrentYear && minCurrentYear) ||
                  (moment().set("years", "2020") && min && moment(min))
                }
                maxDate={
                  (maxCurrentYear && maxCurrentYear) || (max && moment(max))
                }
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                  onChange(newValue._d.getTime());
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: 3,
              position: "relative",
              background: "#fff",
              tranform: "translateY(-50px)",
              px: 3,
              alignItems: "center",
            }}
          >
            <Box component="p" sx={{ fontSize: "1.25rem" }}>
              {`${date.get("hours")}`.padStart(2, "0")}:
              {`${date.get("minutes")}`.padStart(2, "0")}
            </Box>

            <Box sx={{ width: "100%" }}>
              <Slider
                value={date.get("hours")}
                onChange={(e, newValue) => handleTime(e, newValue, "hr")}
                min={0}
                max={23}
                size="small"
                sx={{ p: 0, m: 0 }}
              />
              <Slider
                value={date.get("minutes")}
                onChange={(e, newValue) => handleTime(e, newValue, "min")}
                min={0}
                max={59}
                size="small"
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default DateTime;
