import moment from "moment";
import React, { useEffect, useState, useRef } from "react";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

import { Box, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";

/**
 *
 * @param props {object}  property of component
 * @param props.value {number} date in millisecond
 * @param props.onChange {functn} Triggers when input changes and return time in millisec
 * @returns
 */

function DateTime({ onChange, value, label, min, max, error }) {
  const elRef = useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(moment());

  useEffect(() => {
    if (value) {
      setDate(moment(value));
    }
  }, [value]);
  //closing calendar display on clicking else where
  useEffect(() => {
    function handleListener(e) {
      if (!elRef?.current?.contains(e.target)) {
        setShowCalendar(false);
      }
    }
    document.addEventListener("click", handleListener);
    return () => document.removeEventListener("click", handleListener);
  }, []);

  return (
    <Box
      sx={{ position: "relative", width: "fit-content", zIndex: 40 }}
      ref={elRef}
    >
      <TextField
        label={label}
        value={date.format("DD-MM-yyyy ")}
        onClick={() => setShowCalendar((prevValue) => !prevValue)}
        readOnly
        size="small"
        type={error ? "error" : ""}
      />
      {Boolean(showCalendar) && (
        <Box
          sx={{
            position: "absolute",
            background: "#fff",
            top: 26,
            zIndex: 40,
            filter:
              "drop-shadow(0 10px 8px rgb(0 0 0 / 0.03)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.03))",
            mt: 2,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              minHeight: "330px",
              border: `1px solid ${grey[100]}`,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                minDate={(min && moment(min)) || moment().set("years", 2020)}
                maxDate={max && moment(max)}
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                  onChange(newValue._d.getTime());
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      )}
    </Box>
  );
}
  
export default DateTime;
