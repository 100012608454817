import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import logger from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import notifierUIReducer from "./slices/notifier.slice";
import backupSlice from "./slices/backup.slice";
import cloudConnectSlice from "./slices/cloud-connect.slice";
import userReducer from "./slices/user.slice";
import commonSlice from "./slices/common.slice";
import instanceSlice from "./slices/instances.slice";
import lamaSlice from "./slices/lama.slice";
import tickets from "./slices/tickets.slice";
import datacenterSlice from "./slices/datacenter.slice";
import bannerInfo from "./slices/bannerInfo.slice";

import extendSlice from "./slices/extend.slice";
import pauseSlice from "./slices/pauseAlert.slice";

const allReducers = combineReducers({
  extendSlice: extendSlice.reducer,
  pauseSlice: pauseSlice.reducer,
  datacenters: datacenterSlice.reducer,
  user: userReducer.reducer,
  backup: backupSlice.reducer,
  cloud_connect: cloudConnectSlice.reducer,
  notifiers: notifierUIReducer.reducer,
  instance: instanceSlice.reducer,
  tickets: tickets.reducer,
  bannerInfo: bannerInfo,
  common: commonSlice.reducer,
  lama: lamaSlice.reducer,
});
const persistConfig = {
  key: "root",
  storage,
  blacklist: ["lama","cloud_connect"],
};
const persistedReducer = persistReducer(persistConfig, allReducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV !== "Production",
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_NODE_ENV !== "Production"
      ? getDefaultMiddleware({
          serializableCheck: false,
          immutableCheck: false,
        }).concat(logger)
      : getDefaultMiddleware(),
});

export const persistor = persistStore(store);
export { store };
