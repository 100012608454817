import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  backup_agents,
  backup_overview,
  backup_status,
  vm_job,
} from "../../config/server";
import { axiosGet, getError } from "utils/axios-api.util";
import { logout } from "../actions";

//============================== INITIAL STATE =====================
const initialState = {
  status: {
    isLoading: false,
    data: {
      message: "",
      contract_id: null,
      flag: null,
      pool_id: null,
      start_date: null,
      end_date: null,
      planDetails: [],
      max_agent: 0,
      max_storage: 0,
      per_agent_cost: 0,
      per_gb_cost: 0,
      no_of_month: 0,
      gst: 0,
    },
    isError: false,
    error: "",
  },

  vms: { isLoading: false, data: [], isError: false, error: "" },

  overview: {
    isLoading: false,
    data: {
      total_bytes: 0,
      used_bytes: 0,
      total_gb: 0,
      used_gb: 0,
      total_agents: 0,
      assigned_agents: 0,
      total_jobs: 0,
      success_jobs: 0,
      failed_jobs: 0,
      backup_flag: 0,
      contract_end_date: 0,
      contract_end_time: 0,
      no_of_months_to_exp: 0,
      no_of_days_to_exp: 0,
      jobs: [],
    },
    isError: false,
    error: "",
  },
  jobs: {
    isLoading: false,
    data: [],
    isError: false,
    error: "",
  },
};
//============================= THUNK ============================

//--------------------- STATUS -----------------------/
export const fetchBackupStatus = createAsyncThunk(
  "backup-status",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const datacenter = state.datacenters?.selected?.u_location;
      const res = await axiosGet(backup_status(datacenter), token);

      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(errMsg);
      throw errMsg;
    }
  }
);

//--------------------- OVERVIEW -----------------------
export const fetchBackupOverview = createAsyncThunk(
  "backup-overview",
  async (poolid, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const datacenter = state.datacenters?.selected?.u_location;
      const res = await axiosGet(backup_overview(datacenter, poolid), token);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(errMsg);
      throw errMsg;
    }
  }
);

//------------------ VMS (INSTANCES) ----------------
export const fetchBackupVM = createAsyncThunk(
  "backup-vm",
  async (poolid, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const datacenter = state.datacenters?.selected?.u_location;
      const res = await axiosGet(backup_agents(datacenter, poolid), token);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(errMsg);
      throw errMsg;
    }
  }
);

//-------------------- JOBS ---------------------
export const fetchBackupJobs = createAsyncThunk(
  "backup-jobs",
  async ({ poolid, clientid }, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const datacenter = state.datacenters?.selected?.u_location;
      const res = await axiosGet(vm_job(datacenter, poolid, clientid), token);
      if (res.data.message) {
        return res.data.message;
      } else {
        return [];
      }
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(errMsg);
      throw errMsg;
    }
  }
);

//========================== SLICE =========================
const backupSlice = createSlice({
  name: "backup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //================= WHEN LOGGED OUT ===========
    builder.addCase(logout.toString(), (state, action) => {
      return initialState;
    });

    //======================= STATUS =======================
    builder.addCase(fetchBackupStatus.pending, (state, action) => {
      state.overview = {
        isLoading: false,
        data: {
          total_bytes: 0,
          used_bytes: 0,
          total_gb: 0,
          used_gb: 0,
          total_agents: 0,
          assigned_agents: 0,
          total_jobs: 0,
          success_jobs: 0,
          failed_jobs: 0,
          backup_flag: 0,
          contract_end_date: 0,
          contract_end_time: 0,
          no_of_months_to_exp: 0,
          no_of_days_to_exp: 0,
          jobs: [],
        },
        isError: false,
        error: "",
      };
      state.jobs = {
        isLoading: false,
        data: [],
        isError: false,
        error: "",
      };
      state.vms = { isLoading: false, data: [], isError: false, error: "" };
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = "";
    });
    builder.addCase(fetchBackupStatus.fulfilled, (state, action) => {
      state.status.isLoading = false;
      state.status.data = action.payload;
    });
    builder.addCase(fetchBackupStatus.rejected, (state, action) => {
      state.status = {
        isLoading: false,
        data: {
          message: "",
          contract_id: null,
          flag: null,
          pool_id: null,
          start_date: null,
          end_date: null,
          planDetails: [],
          max_agent: 0,
          max_storage: 0,
          per_agent_cost: 0,
          per_gb_cost: 0,
          no_of_month: 0,
          gst: 0,
        },
        isError: false,
        error: "",
      };
      if (action.payload) {
        state.status.error = action.payload.errorMessage;
      } else {
        state.status.error = action.error.message;
      }
    });

    //======================= OVERVIEW =======================
    builder.addCase(fetchBackupOverview.pending, (state, action) => {
      state.overview.isLoading = true;
      state.overview.isError = false;
      state.overview.error = "";
    });
    builder.addCase(fetchBackupOverview.fulfilled, (state, action) => {
      state.overview.isLoading = false;
      state.overview.data = action.payload;
    });
    builder.addCase(fetchBackupOverview.rejected, (state, action) => {
      state.overview.isLoading = false;
      state.overview.isError = true;
      state.overview.error = action.error;
    });

    //======================= VM (INSTANCES) =======================
    builder.addCase(fetchBackupVM.pending, (state, action) => {
      state.vms.isLoading = true;
      state.vms.isError = false;
      state.vms.error = "";
    });
    builder.addCase(fetchBackupVM.fulfilled, (state, action) => {
      state.vms.isLoading = false;
      state.vms.data = action.payload;
    });
    builder.addCase(fetchBackupVM.rejected, (state, action) => {
      state.vms.isLoading = false;
      state.vms.isError = true;
      state.vms.error = action.error;
    });

    //======================= JOBS =======================
    builder.addCase(fetchBackupJobs.pending, (state, action) => {
      state.jobs.isLoading = true;
      state.jobs.isError = false;
      state.jobs.error = "";
    });
    builder.addCase(fetchBackupJobs.fulfilled, (state, action) => {
      state.jobs.isLoading = false;
      state.jobs.data = action.payload;
    });
    builder.addCase(fetchBackupJobs.rejected, (state, action) => {
      state.jobs.isLoading = false;
      state.jobs.isError = true;
      state.jobs.error = action.error;
    });
  },
});

export default backupSlice;
