import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import logo from "assets/img/logo.png";
import cover from "assets/img/Data _extraction_bro.png";
import PasswordInput from "components/UI/Input/password-input";
import { Button } from "components/features/UI/Inputs";
import {
  LAMA_SIGNUP,
  OTP_EMAIL_VERIFICATION,
  OTP_VERIFICATION,
  RESEND_EMAIL_OTP,
  RESEND_OTP,
  partner_link,
  zTalk,
} from "config/server";
import { MdInfoOutline } from "react-icons/md";
import Validator from "utils/validator";
import ConfirmPasswordInput from "components/UI/Input/confirm-password";
import OtpMobileVerification from "./OtpMobileVerification";
import axios from "axios";
import { getError } from "utils/axios-api.util";
import { toastUpdateError, toastUpdateSuccess } from "utils/toast.util";
import { toast } from "react-toastify";
import CompleteProfile from "./CompleteProfile";

import EmailInput from "components/UI/Input/email-input";
import { BsTelephone } from "react-icons/bs";
import OtpEmailVerification from "./OtpEmailVerification";

const style = {
  col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "baseline",
  },
  heading: {
    fontWeight: 500,
  },
};

const SignUp = () => {
  const [userCredentials, setUserCredentials] = useState({
    email: { value: "", error: null },
    password: { value: "", error: null },
    confirmPassword: { value: "", error: null },
    gst: { value: "", error: null },
    phone: { value: "", error: null },
  });

  const [sentOtpStatus, setSentOtpStatus] = useState({
    error: "",
    loading: false,
    data: {},
  });

  const [otpVerified, setotpVerified] = useState({
    loading: false,
    error: false,
    data: "",
  });
  const [otpEmailVerified, setOtpEmailVerfied] = useState({
    loading: false,
    error: false,
    data: "",
  });
  const [isValid, setIsValid] = useState(true);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  function checkValidation(type, value) {
    const validate = new Validator(value);
    if (type === "email") {
      return !validate?.newEmail();
    }
    if (type === "password") {
      return !validate?.password();
    }
    if (type === "confirmPassword") {
      return !validate?.password();
    }

    if (type === "phone") {
      return !validate?.mobile();
    }
  }

  function checkIsValid(name, value) {
    if (name === "confirmPassword") {
      setIsValid(false);
      if (userCredentials?.password?.value === value) {
        setIsValid(true);
      }
    }
  }
  function handleInputChange(e) {
    const { value, name } = e.target;

    const validity = checkValidation(name, value);
    if (name === "confirmPassword") {
      checkIsValid(name, value);
    }

    setUserCredentials((prevValue) => ({
      ...prevValue,
      [name]: { value, error: validity },
    }));
  }

  async function handleSendOtp(data) {
    const toast_id = "sending otp...";
    setSentOtpStatus((pre) => {
      return {
        ...pre,
        loading: true,
      };
    });
    try {
      let res = await axios.post(LAMA_SIGNUP, data);

      setSentOtpStatus((pre) => {
        return {
          ...pre,
          loading: false,
          data: res?.data ?? [],
        };
      });
      toastUpdateSuccess(toast_id, res?.data?.message?.message ?? "");
      setIsEmailVerified(true);
    } catch (err) {
      setSentOtpStatus((pre) => {
        return {
          ...pre,
          loading: false,
          error: err?.response?.data?.message ?? "",
        };
      });
    }
  }
  /*---- intial submit for signup ---*/
  async function handleSubmit(e) {
    e.preventDefault();
    let data = {
      email: userCredentials?.email?.value?.toLowerCase() ?? "",
      password: userCredentials?.password?.value ?? "",
      c_password: userCredentials?.confirmPassword?.value ?? "",
      phone: userCredentials?.phone?.value ?? "",
    };
    handleSendOtp(data);
  }

  /*---- resend otp ---*/
  async function handleResendOTP() {
    const toast_id = "resending otp...";
    let mobile = userCredentials?.phone?.value ?? "";
    try {
      const res = await axios.post(RESEND_OTP(mobile));
      toastUpdateSuccess(toast_id, res?.data?.message ?? "");
    } catch (err) {
      const errMsg = getError(err);
      toast.error(errMsg ?? "");
      toastUpdateError(toast_id, errMsg ?? "");
    }
  }
  async function handleResendEmailOTP() {
    const toast_id = "resending otp...";

    let email = userCredentials?.email?.value ?? "";
    try {
      const res = await axios.post(RESEND_EMAIL_OTP(email));
      toastUpdateSuccess(toast_id, res?.data?.message ?? "");
    } catch (err) {
      const errMsg = getError(err);
      toast.error(errMsg ?? "");
      toastUpdateError(toast_id, errMsg ?? "");
    }
  }
  const handleLogin = () => {
    history.push("/");
  };
  /* ----- OTP Verified ----- */
  const handleSubmitMobileOtp = async (e, otp) => {
    const toast_id = "Submiting otp...";
    e.preventDefault();
    setIsEmailVerified(false);
    let info = {
      mobile: userCredentials?.phone?.value ?? "",
      otp: otp ?? "",
    };

    setotpVerified((pre) => {
      return {
        ...pre,
        loading: true,
      };
    });
    try {
      let res = await axios.get(OTP_VERIFICATION(info?.mobile, info?.otp));

      setotpVerified((pre) => {
        return {
          ...pre,
          loading: false,
          data: res?.data?.message ?? [],
        };
      });

      if (!res?.data?.error) {
        toastUpdateSuccess(toast_id, "Verified mobile number");
        setIsSignUp(true);
      } else {
        toast.error(res?.data?.message?.message ?? "");
      }
    } catch (error) {
      setotpVerified((pre) => {
        return {
          ...pre,
          loading: false,
          error: error?.message ?? "",
        };
      });
      const errMsg = getError(error, dispatch);
      toastUpdateError(toast_id, errMsg ?? "");
    }
  };
  const handleSubmitEmailOtp = async (e, otp) => {
    const toast_id = "Submiting otp...";
    e.preventDefault();
    let info = {
      email: userCredentials?.email?.value ?? "",
      otp: otp ?? "",
    };
    setOtpEmailVerfied((pre) => {
      return {
        ...pre,
        loading: true,
      };
    });
    try {
      let res = await axios.get(OTP_EMAIL_VERIFICATION(info?.email, info?.otp));
      setOtpEmailVerfied((pre) => {
        return {
          ...pre,
          loading: false,
          data: res?.data?.message ?? [],
        };
      });

      if (!res?.data?.error) {
        toastUpdateSuccess(toast_id, "Verified email address");
        setIsMobileVerified(true);
      } else {
        toast.error(res?.data?.message?.message ?? "");
      }
    } catch (error) {
      setOtpEmailVerfied((pre) => {
        return {
          ...pre,
          loading: false,
          error: error?.message ?? "",
        };
      });
      const errMsg = getError(error, dispatch);
      toastUpdateError(toast_id, errMsg ?? "");
    }
  };

  useEffect(() => {
    document.title = "ZyBiSys | Signup";
  }, []);

  return (
    <>
      {isSignUp ? (
        <CompleteProfile data={sentOtpStatus?.data ?? {}} />
      ) : isMobileVerified ? (
        <OtpMobileVerification
          handleResendOTP={handleResendOTP}
          phone={userCredentials?.phone?.value ?? ""}
          handleSubmitOtp={handleSubmitMobileOtp}
          otpVerified={otpVerified ?? {}}
        />
      ) : isEmailVerified ? (
        <OtpEmailVerification
          handleResendOTP={handleResendEmailOTP}
          email={userCredentials?.email?.value ?? ""}
          handleSubmitOtp={handleSubmitEmailOtp}
          otpVerified={otpEmailVerified ?? {}}
        />
      ) : (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{
            minHeight: "100vh",
            width: "100%",
            maxWidth: "1100px",
            margin: "0 auto",
            padding: 3,
            overflow: "auto",
          }}
          columnSpacing={3}
          rowSpacing={2}
          overflow="auto"
        >
          <Grid item lg={6} sx={style.col}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                width: "100%",
              }}
            >
              <Link to="/signup">
                <Box
                  component="img"
                  src={logo}
                  alt="logo"
                  sx={{ width: "120px" }}
                />
              </Link>
              <Typography sx={style.heading} variant="h3">
                Cloud Operating Console
              </Typography>
              <Box
                component="img"
                src={cover}
                alt="cover"
                sx={{
                  height: "350px",
                  margin: "10px auto",
                }}
              />

              <FooterLinks />
            </Box>
          </Grid>

          <Grid item lg={6} sx={style.col}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                marginLeft: "50px",
                maxWidth: "25rem",
                width: "100%",
                padding: "20px",
                minHeight: "30rem",
                backgroundColor: "rgba(255, 255, 255, 1)",
                boxShadow: "0 10px 100px #dddddd",
                borderRadius: "15px",
                "& @media screen and (max-width: 720px)": {
                  marginLeft: "0px",
                  maxWidth: "100%",
                  width: "100%",
                  padding: "20px",
                  minHeight: "inherit",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderRadius: "15px",
                },
              }}
            >
              <div>
                {sentOtpStatus?.error && (
                  <Box
                    component="div"
                    sx={{
                      textAlign: "center",
                      fontSize: "17px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontSize: "15px" }}
                      color={theme.palette.red[500]}
                    >
                      {sentOtpStatus?.error ?? ""}
                    </Typography>
                  </Box>
                )}
                <Typography
                  variant="h3"
                  sx={{
                    textAlign: "center",
                    fontWeight: "700",
                    fontSize: "24px",
                  }}
                >
                  Create an Account
                </Typography>
                <Typography
                  component="p"
                  variant="caption"
                  sx={{
                    textAlign: "center",
                    padding: "0px 5px",
                    margin: "0px auto",
                    color: "#8D8B8B",
                    fontWeight: "400",
                  }}
                >
                  Embrace Modern Excellence. Elevate Your Business. Sign Up Now.
                </Typography>
              </div>

              <Box sx={{ width: "100%", padding: "7px" }}>
                <form onSubmit={handleSubmit}>
                  <EmailInput
                    fullWidth
                    margin="normal"
                    label="Company Email"
                    id="login-email"
                    name="email"
                    onChange={handleInputChange}
                    placeholder=""
                  />

                  <PasswordInput
                    fullWidth
                    margin="normal"
                    id="login-password"
                    label="Set Password"
                    name="password"
                    variant="outlined"
                    value={userCredentials?.password?.value ?? ""}
                    onChange={handleInputChange}
                    error={userCredentials?.password?.error ?? ""}
                    helperText={
                      userCredentials?.password?.error ? (
                        <>
                          <HelperText
                            errMsg="Please enter valid password"
                            details={[
                              "• Minimum 8 character",
                              " • Atleast 1 uppercase",
                              "• Atleast 1 lowercase",
                              "• Atleast 1 number",
                              "• Atleast 1 special character (Eg: !,@,#,$,%,^,&,*)",
                            ]}
                          />
                        </>
                      ) : (
                        ""
                      )
                    }
                  />
                  <ConfirmPasswordInput
                    fullWidth
                    margin="normal"
                    id="login-confirmPassword"
                    label="Confirm Password"
                    name="confirmPassword"
                    variant="outlined"
                    value={userCredentials?.confirmPassword?.value ?? ""}
                    onChange={handleInputChange}
                    error={!isValid}
                    helperText={
                      !isValid ? (
                        <>
                          <Box
                            component="ul"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Box component="li" sx={{ mr: "10px" }}>
                              Please enter valid password
                            </Box>
                          </Box>
                        </>
                      ) : (
                        ""
                      )
                    }
                  />
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    margin="normal"
                    error={userCredentials?.phone?.error ?? ""}
                    value={userCredentials?.phone?.value ?? ""}
                    name="phone"
                    onChange={handleInputChange}
                    required
                    label="Mobile"
                    variant="outlined"
                    maxlength="10"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BsTelephone />
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      userCredentials?.phone?.error ? (
                        <HelperText
                          errMsg="Invalid mobile no"
                          details={[
                            "Mobile number should be 10 digit",
                            " Number should be start with 6,7,8 and 9",
                          ]}
                        />
                      ) : (
                        ""
                      )
                    }
                  />

                  <Button
                    sx={{ marginTop: "12px" }}
                    variant="contained"
                    disabled={
                      userCredentials?.email?.error ||
                      !userCredentials?.email?.value ||
                      userCredentials?.password?.error ||
                      !userCredentials?.password?.value ||
                      userCredentials?.phone?.error ||
                      !userCredentials?.phone?.value ||
                      userCredentials?.confirmPassword?.error ||
                      !userCredentials?.confirmPassword?.value
                    }
                    fullWidth
                    type="submit"
                    onClick={handleSubmit}
                    loading={sentOtpStatus?.loading}
                    loadingText="Logging In..."
                  >
                    Sign Up
                  </Button>
                </form>
                <Box
                  component={"div"}
                  sx={{
                    width: "100%",
                    marginTop: "5px",
                    fontSize: "12px",
                    color: "red",
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                  href={partner_link}
                >
                  Already have an account?
                  <Box
                    component={"a"}
                    sx={{
                      marginLeft: "6px",
                      color: theme.palette.primary.main,
                      cursor: "pointer",
                      fontWeight: 700,
                    }}
                    onClick={handleLogin}
                  >
                    Login here
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SignUp;

export function HelperText({ errMsg, details }) {
  return (
    <>
      <Box
        component="ul"
        sx={{
          display: "flex",
          margin: "auto,0px",
          width: "100%",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Box component="li" sx={{ mr: "10px" }}>
          {errMsg ?? ""}
        </Box>
        <Tooltip
          arrow
          title={
            <>
              <Box component={"ul"}>
                {details?.map((val) => {
                  return <Box component={"li"}>{val ?? ""}</Box>;
                })}
              </Box>
            </>
          }
        >
          <Box
            component={"span"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MdInfoOutline color="#FF9F29" size={15} />
          </Box>
        </Tooltip>
      </Box>
    </>
  );
}

function FooterLinks() {
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap={3}>
      <Box
        component="a"
        href="#"
        sx={{
          color: theme.palette.primary.main,
          fontSize: "0.8rem",
          fontWeight: 600,
          "&:hover": { color: theme.palette.primary.dark },
        }}
      >
        Terms & Conditions
      </Box>
      <Box
        component="a"
        href={zTalk}
        target="_blank"
        sx={{
          color: theme.palette.primary.main,
          fontSize: "0.8rem",
          fontWeight: 600,
          "&:hover": { color: theme.palette.primary.dark },
        }}
      >
        Blog
      </Box>
      <Box
        component="a"
        href="mailto:cloudsupport@zybisys.com"
        sx={{
          color: theme.palette.primary.main,
          fontSize: "0.8rem",
          fontWeight: 600,
          "&:hover": { color: theme.palette.primary.dark },
        }}
      >
        Support
      </Box>
      <Box
        component="a"
        href="mailto:sales@zybisys.com"
        sx={{
          color: theme.palette.primary.main,
          fontSize: "0.8rem",
          fontWeight: 600,
          "&:hover": { color: theme.palette.primary.dark },
        }}
      >
        Sales
      </Box>
    </Stack>
  );
}
