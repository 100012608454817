//Auth
export const LOGOUT = "/logout";
export const LOGIN = "/login";
export const SET_PASSWORD = "/set-password";
export const FORGOT_PASSWORD = "/forgot_password";
export const RESET_PASSWORD = "/reset_password";
export const VERIFY_EMAIL = "/verify-user";

//--------- APPLICATION PATHS -------------
//---------- lama sign up -----------------
export const LAMA_SIGNUP = "/signup";

//Root
export const COC = "/coc";

//Dashboard
export const DASHBOARD = `${COC}/dashboard`;
export const ASSIGNPARTNER = `${DASHBOARD}/partner-data`;

//Privilege
export const PRIVILEGE = `${COC}/privilege`;
export const PRIVILEGE_USER = `${PRIVILEGE}/users`;

//Manage

export const MANAGE = `${COC}/manage`;
export const LAMA = `${COC}/manage/lama`;
export const MANAGE_INSTANCE = `${MANAGE}/instance`;
export const MANAGE_FIREWALL = `${MANAGE}/firewall`;
export const MANAGE_CLOUDCONNECT = `${MANAGE}/cloud_connect`;
export const MANAGE_SETTING = `${MANAGE}/setting`;
export const MANAGE_ZOOMVIEW = `${MANAGE}/zoom_view`;
export const MANAGE_ZOOMVIEW_SERVICES = `${MANAGE_ZOOMVIEW}/services`;
export const MANAGE_ZOOMVIEW_GRAPHS = `${MANAGE_ZOOMVIEW}/graphs`;
export const MANAGE_ZOOMVIEW_INTERNET = `${MANAGE_ZOOMVIEW}/internet`;
export const MANAGE_BACKUP = `${MANAGE}/backup`;
export const MANAGE_BACKUP_OVERVIEW = `${MANAGE_BACKUP}/overview`;
export const MANAGE_BACKUP_JOBS = `${MANAGE_BACKUP}/jobs`;
export const MANAGE_BACKUP_CONFIGURATION = `${MANAGE_BACKUP}/configuration`;
export const MANAGE_LAMA = `${MANAGE}/lama`;
export const MANAGE_LAMA_UAT = `${MANAGE_LAMA}?env_type=uat`;
export const MANAGE_LAMA_PROD = `${MANAGE_LAMA}?env_type=prod`;

//Backup
export const BACKUP_JOBHISTORY = `${MANAGE}/job-history`;
export const BACKUP_PAYMENT = `${COC}/backup-payment`;
export const BACKUP_PAYMENTSUCCESS = `${BACKUP_PAYMENT}/payment_success`;
export const BACKUP_PAYMENTFAILED = `${BACKUP_PAYMENT}/payment_failed`;

//Billing
export const BILLING = `${COC}/billing`;
export const BILLING_INVOICES = `${BILLING}/invoices`;
export const BILLING_PAYMENTHISTORY = `${BILLING}/payment_history`;
export const BILLING_PAYMENTSUCCESS = `${BILLING}/payment_success`;
export const BILLING_PAYMENTFAILED = `${BILLING}/payment_failed`;
export const BILLING_PLAN = `${BILLING}/plan`;
export const BILLING_BACKUP_PLAN = `${BILLING}/backup-plan`;
export const BILLING_BACKUP_PLANDETAIL = `${BILLING}/backup-plan-detail`;

//Reports
export const REPORTS = `${COC}/reports`;
export const REPORTS_BANDWIDTH = `${REPORTS}/bandwidth`;
export const REPORTS_IPTRACKING = `${REPORTS}/ip_tracking`;
export const REPORTS_TOPAPPS = `${REPORTS}/top_apps`;
export const REPORTS_GEOTRAFFIC = `${REPORTS}/geo_traffic`;
export const REPORTS_IPREPORT = `${REPORTS}/summary_report`;
export const LAMA_REPORTS = `${REPORTS}/lama_report`;
export const CXO_MAIL_LOGS = `${REPORTS}/cxo_mail_logs`;

//UserProfile
export const USERPROFILE = `${COC}/profile`;
export const DOCUMENTATION = `${COC}/docs`;
export const DOC_INSTALLATION = `${DOCUMENTATION}/installation`;
export const DOC_GETTING_STARTED = `${DOCUMENTATION}/getting_started`;

//Tickets
export const TICKETS = `${COC}/tickets`;
