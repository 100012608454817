import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import { instanceIp } from "config/server";
import { axiosGet, getError } from "utils/axios-api.util";

const initialState = {
  isLoading: false,
  isError: false,
  instance_list: [],
  instance_ip: {},
  error: "",
};

export const fetchInstancesIp = createAsyncThunk(
  "instance_data",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const datacenter = state.datacenters?.selected?.u_location;
      const res = await axiosGet(instanceIp(datacenter), token);
      const instance_list = res.data.message;
      const instance_ips = instance_list.reduce((acc, curr) => {
        acc[curr.lan_ip] = curr;
        return acc;
      }, {});
      return { instance_ips, instance_list };
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(errMsg);
      throw errMsg;
    }
  }
);

const instanceSlice = createSlice({
  name: "instance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //================= WHEN LOGGED OUT ===========
    builder.addCase("LOGOUT", (state, action) => {
      return initialState;
    });
    //------------------instanceIp---------/
    builder.addCase(fetchInstancesIp.pending, (state, action) => {
      state.isLoading = true;
      state.isError = false;
      state.error = "";
      state.instance_list = [];
      state.instance_ip = {};
    });
    builder.addCase(fetchInstancesIp.fulfilled, (state, action) => {
      state.instance_list = action.payload.instance_list;
      state.instance_ip = action.payload.instance_ips;
      state.isLoading = false;
    });
    builder.addCase(fetchInstancesIp.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.error;
    });
  },
});

export default instanceSlice;
