import axios from "axios";
import { decodeStrBase64 } from "./util";
import { onShowLogoutNotifier } from "store/slices/notifier.slice";

export async function axiosGet(url, tkn, signal) {
  const token = decodeStrBase64(tkn, "VmUYLszbyV");
  const config = {
    headers: { authorization: `bearer ${token}` },
  };
  if (signal) {
    config.signal = signal;
  }

  return axios.get(url, config).catch((err) => {
    throw err;
  });
}
export async function axiosPost(url, data, tkn) {
  const token = decodeStrBase64(tkn, "VmUYLszbyV");
  const config = {
    headers: { authorization: `bearer ${token}` },
  };
  return axios.post(url, data, config).catch((err) => {
    throw err;
  });
}

export async function axiosDelete(url, tkn) {
  const token = decodeStrBase64(tkn, "VmUYLszbyV");
  const config = {
    headers: { authorization: `bearer ${token}` },
  };

  return axios.delete(url, config).catch((err) => {
    throw err;
  });
}

export async function axiosPut(url, data, tkn) {
  const token = decodeStrBase64(tkn, "VmUYLszbyV");
  const config = {
    headers: { authorization: `bearer ${token}` },
  };
  return axios.put(url, data, config).catch((err) => {
    throw err;
  });
}
export async function axiosPatch(url, data, tkn) {
  const token = decodeStrBase64(tkn, "VmUYLszbyV");
  const config = {
    headers: { authorization: `bearer ${token}` },
  };
  return axios.patch(url, data, config).catch((err) => {
    throw err;
  });
}

//----------- axios utility --------------
export function getError(err, dispatch) {
  if (err.response) {
    const msg = err.response.data.message;
    if (dispatch && isUserLoggedOut(msg)) {
      dispatch(onShowLogoutNotifier(msg));

      return "Something went wrong.";
    }

    return msg;
  } else {
    return err.message;
  }
}
function isUserLoggedOut(msg) {
  if (msg === "logout" || msg === "Session Expired") {
    return true;
  }
  return false;
}
