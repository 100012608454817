import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pause: false,
};

const pauseSlice = createSlice({
  name: "extendCom",
  initialState,
  reducers: {
    setPauseAlert: (state, action) => {
      return {
        pause: action.payload,
      };
    },
  },
});

export const { setPauseAlert } = pauseSlice.actions;

export default pauseSlice;
