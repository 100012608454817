import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../actions";

//============== INITIAL STATE ===========================
const initialState = {
  logoutDialog: { bool: false, reason: "" },
};

//==================== SLICE ===============================
const notifierSlice = createSlice({
  name: "notifiers",
  initialState,
  reducers: {
    onShowLogoutNotifier(state, action) {
      return {
        ...state,
        logoutDialog: { bool: true, reason: action.payload },
      };
    },
    onResetLogoutNotifier(state, action) {
      return {
        ...state,
        logoutDialog: { bool: false, reason: "" },
      };
    },
  },
  extraReducers: (builder) => {
    //================= WHEN LOGGED OUT ===========
    builder.addCase(logout, (state, action) => {
      return initialState;
    });
  },
});

export const { onShowLogoutNotifier, onResetLogoutNotifier } =
  notifierSlice.actions;
export default notifierSlice;
