import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { axiosGet, getError } from "utils/axios-api.util";
import {
  bellNotification,
  bellNotificationUnseen,
  feedback,
  updateBellNotification,
} from "config/server";

const today = new Date();

const initialState = {
  searched_list: [],
  search_loader: true,
  error: null,
  feedback_message: null,
  feedbackRated: false,
  feedbackCounted: 0,
  feedbackLoader: false,
  feedback_error: null,

  loadingNotifications: true,
  notifications_arr: [],
  unseen_notifications_arr: [],
  notifications_suucess_msg: null,
  notifications_error_msg: null,

  update_suc_notification_msg: null,
  update_err_notification_msg: null,

  datetime: {
    to: today,
    from: new Date(today.getTime() - 15 * 60 * 1000),
  },
};

// export const searching = createAsyncThunk(
//   "searching",
//   async (searched_name, thunkApi) => {
//     try {
//       const state = thunkApi.getState();
//       const token = state.user?.token;
//       const datacenter = state.datacenters?.selected?.u_location;
//       const res = await axiosGet(search(searched_name, datacenter), token);
//       return res.data.message;
//     } catch (err) {
//       const errMsg = getError(err, thunkApi.dispatch);
//       thunkApi.rejectWithValue(err);
//       throw errMsg;
//     }
//   }
// );

export const feedbackRated = createAsyncThunk(
  "feedbackRated",
  async (rating, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosGet(feedback(rating), token);
      return res.data.message;
    } catch (arr) {}
  }
);

export const fetchNotifications = createAsyncThunk(
  "fetch_notification",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosGet(bellNotification, token);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      throw errMsg;
    }
  }
);

export const updateNotifications = createAsyncThunk(
  "update_notification",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosGet(bellNotification, token);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      throw errMsg;
    }
  }
);

export const fetchUnseenNotifications = createAsyncThunk(
  "Unseen_notification",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosGet(bellNotificationUnseen, token);
      thunkApi.dispatch(updateNotifications());
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      throw errMsg;
    }
  }
);

export const updateUnseenNotifications = createAsyncThunk(
  "update_unseen_notification",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosGet(updateBellNotification, token);
      return res.data.message;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      throw errMsg;
    }
  }
);

const commonSlice = createSlice({
  name: "commom_slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(feedbackRated.pending, (state, action) => {
        state.feedbackLoader = true;
      })
      .addCase(feedbackRated.fulfilled, (state, action) => {
        state.feedback_message = action.payload;
        state.feedbackLoader = false;
        state.feedbackRated = true;
      })
      .addCase(feedbackRated.rejected, (state, action) => {
        state.feedback_error = action.error.message;
        state.feedbackRated = false;
        state.feedbackLoader = false;
      })
      .addCase(fetchNotifications.pending, (state, action) => {
        state.loadingNotifications = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.loadingNotifications = false;
        state.notifications_arr = action.payload;
        state.notifications_error_msg = "";
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.loadingNotifications = false;
        state.notifications_arr = [];
        state.notifications_error_msg = action.error.message;
      })
      .addCase(updateNotifications.pending, (state, action) => {
        state.loadingNotifications = true;
      })
      .addCase(updateNotifications.fulfilled, (state, action) => {
        state.loadingNotifications = false;
        state.notifications_arr = action.payload;
        state.notifications_error_msg = "";
      })
      .addCase(updateNotifications.rejected, (state, action) => {
        state.loadingNotifications = false;
        state.notifications_arr = [];
        state.notifications_error_msg = action.error.message;
      })
      .addCase(fetchUnseenNotifications.pending, (state, action) => {
        state.loadingNotifications = true;
      })
      .addCase(fetchUnseenNotifications.fulfilled, (state, action) => {
        state.unseen_notifications_arr = action.payload;
        state.loadingNotifications = false;
        state.notifications_error_msg = "";
      })
      .addCase(fetchUnseenNotifications.rejected, (state, action) => {
        state.unseen_notifications_arr = [];
        state.loadingNotifications = false;
        state.notifications_error_msg = action.error.message;
      })
      .addCase(updateUnseenNotifications.pending, (state, action) => {
        state.loadingNotifications = true;
      })
      .addCase(updateUnseenNotifications.fulfilled, (state, action) => {
        state.update_suc_notification_msg = action.payload;
        state.loadingNotifications = false;
        state.update_err_notification_msg = null;
      })
      .addCase(updateUnseenNotifications.rejected, (state, action) => {
        state.update_suc_notification_msg = null;
        state.loadingNotifications = false;
        state.update_err_notification_msg = action.error.message;
      });
  },
});

export default commonSlice;
