import Icon from "@mui/material/Icon";
import CentOS from "assets/img/centos.png";
import { DiRedhat } from "react-icons/di";
import { FaUbuntu, FaWindows } from "react-icons/fa";
import { FcLinux } from "react-icons/fc";
import { GrVmware } from "react-icons/gr";

import { Box, Tooltip } from "@mui/material";
import { blue, grey, red } from "@mui/material/colors";
import { BsPatchQuestion } from "react-icons/bs";

/*=================== TO GET OS ICON =======================*/

export function getOSIcon(os) {
  const os_str = os.toLowerCase();

  if (os_str.includes("windows")) {
    //--------- WINDOWS ICON ------------
    return (
      <Tooltip title={os} placement="top" arrow>
        <Icon sx={{ fontSize: "1.5rem", color: blue[500] }}>
          <FaWindows />
        </Icon>
      </Tooltip>
    );
  } else if (os_str.includes("centos")) {
    //--------- CENTOS ICON ------------
    return (
      <Tooltip title={os} placement="top" arrow>
        <Icon>
          <Box
            component={"img"}
            src={CentOS}
            alt="cent os"
            sx={{ width: "1.5rem" }}
          />
        </Icon>
      </Tooltip>
    );
  } else if (os_str.includes("rhel") || os_str.includes("red hat")) {
    //--------- REDHAT ICON ------------
    return (
      <Tooltip title={os} placement="top" arrow>
        <Icon sx={{ fontSize: "1.8rem", color: red["A700"] }}>
          <DiRedhat />
        </Icon>
      </Tooltip>
    );
  } else if (os_str.includes("ubuntu")) {
    //--------- UBUNTU ICON ------------
    return (
      <Tooltip title={os} placement="top" arrow>
        <Icon sx={{ fontSize: "1.5rem", color: red["A700"] }}>
          <FaUbuntu />
        </Icon>
      </Tooltip>
    );
  } else if (os_str.includes("linux")) {
    //--------- LINUX ICON ------------
    return (
      <Tooltip title={os} placement="top" arrow>
        <Icon sx={{ fontSize: "1.8rem", color: red["A700"] }}>
          <FcLinux />
        </Icon>
      </Tooltip>
    );
  } else if (os_str.includes("vmware photon")) {
    //--------- VMWARE PHOTON ICON ------------
    return (
      <Tooltip title={os} placement="top" arrow>
        <Icon sx={{ fontSize: "1.5rem", color: blue["A700"] }}>
          <GrVmware />
        </Icon>
      </Tooltip>
    );
  } else {
    //--------- UNKNOWN ICON ------------
    return (
      <Tooltip title={os_str} placement="top" arrow>
        <Icon sx={{ fontSize: "1.5rem", color: grey["A700"] }}>
          <BsPatchQuestion />
        </Icon>
      </Tooltip>
    );
  }
}

/*======================== TO GET BACKUP JOB STATUS =============================== */
export function getBackupJobStatus(status) {}
