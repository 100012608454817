import { createSlice } from "@reduxjs/toolkit";
import { encodeStrBase64 } from "utils/util";
import { logout } from "../actions";

const initialState = {
  user_flag: null,
  userName: null,
  status: null,
  name: null,
  emai: null,
  datacenter: null,
  address: null,
  sub_user_type: null,
  user_type: null,
  user_roles: {
    services: [],
    reports: {
      restart: null,
      schedule_reboot: null,
      manage: null,
      billing: null,
      reports: null,
      dashboard_billing: null,
    },
  },
  token: null,
  customer_number: null,
  user_img: null,
  user_img_thumb: null,
  expiry_time: null,
  backup_flag: null,
  logout: null,
  primary_user: 0,
  logoutReason: false,
  primary_uid: null,
  user_id: null,
  lastSelectedLocation: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLogin(state, action) {
      const { uid, u_id, address, token, emai, datacenter, ...other } =
        action.payload;
      return {
        ...state,
        ...other,
        user_email: encodeStrBase64(emai, "VmUYLszbyV"),
        address: encodeStrBase64(address, "VmUYLszbyV"),
        token: encodeStrBase64(token, "VmUYLszbyV"),
        user_roles: other?.user_roles?.reports ?? 0,
        expiry_time: new Date().getTime() + 1000 * 60 * 60 * 24,
        primary_user: uid === u_id,
        primary_uid: encodeStrBase64(uid, "VmUYLszbyV"),
        user_id: encodeStrBase64(u_id, "VmUYLszbyV"),
        lastSelectedLocation: datacenter,
      };
    },
    setUserName(state, action) {
      return {
        ...state,
        userName: action.payload ?? "",
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(logout.toString(), (state, action) => {
      return { ...initialState, logout: action.payload };
    });
  },
});

export default userSlice;
export const { setLogin, setUserName } = userSlice.actions;
export { logout };
