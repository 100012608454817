import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  extend: true,
};

const extendSlice = createSlice({
  name: "extendCom",
  initialState,
  reducers: {
    setExtend: (state, action) => {
      return {
        extend: action.payload.test,
      };
    },
  },
});

export const { setExtend } = extendSlice.actions;

export default extendSlice;
