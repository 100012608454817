const KB = 1000;
export const FIRST_MAY = 1717113600000;
export const THRESHOLD_DATE = `2024-05-31 : 23:59:59`;
export const LAST_MAY = `20240531`;

export function convertBitToSpeed(value) {
  value = value * 8;
  var magabitdata = value / 1000000; // covert bit to Megabit
  if (magabitdata < 1) {
    value = magabitdata * KB; // covert Megabit to Kilobit
    return parseFloat(value.toFixed(2)) + " Kbps";
  } else if (magabitdata > KB) {
    value = magabitdata / KB; // covert Megabit to Gigabit
    return parseFloat(value.toFixed(2)) + " Gbps";
  } else {
    value = magabitdata;
    return parseFloat(value.toFixed(2)) + " Mbps";
  }
}

export function bitsToGB(bits, precision = 2) {
  let bytes = bits * 8;
  const kilobyte = KB;
  const megabyte = kilobyte * KB;
  const gigabyte = megabyte * KB;

  if (bytes >= 0 && bytes < kilobyte) {
    return bytes + " b";
  } else if (bytes >= kilobyte && bytes < megabyte) {
    return (bytes / kilobyte).toFixed(precision) + " Kb";
  } else if (bytes >= megabyte && bytes < gigabyte) {
    return (bytes / megabyte).toFixed(precision) + " Mb";
  } else if (bytes >= gigabyte) {
    return (bytes / gigabyte).toFixed(precision) + " Gb";
  } else {
    return bytes + " b";
  }
}

export function bytesToGB(bytes, precision = 2) {
  // old one without multiple 8

  const kilobyte = KB;
  const megabyte = kilobyte * KB;
  const gigabyte = megabyte * KB;

  if (bytes >= 0 && bytes < kilobyte) {
    return bytes + " B";
  } else if (bytes >= kilobyte && bytes < megabyte) {
    return (bytes / kilobyte)?.toFixed(precision) + " KB";
  } else if (bytes >= megabyte && bytes < gigabyte) {
    return (bytes / megabyte)?.toFixed(precision) + " MB";
  } else if (bytes >= gigabyte) {
    return (bytes / gigabyte)?.toFixed(precision) + " GB";
  } else {
    return bytes + " B";
  }
}

export function kbToGB(kb, precision = 2) {
  const KB = 1024;
  const MB = KB * KB;
  const GB = MB * KB;

  const bytes = kb * KB;

  if (bytes < MB) {
    return (bytes / KB).toFixed(precision) + " KB";
  } else if (bytes < GB) {
    return (bytes / MB).toFixed(precision) + " MB";
  } else {
    return (bytes / GB).toFixed(precision) + " GB";
  }
}

export function byteToGigaBits(bytes, precision = 2) {
  // new one without 1024
  let KB = 1024;
  const kilobyte = KB;
  const megabyte = kilobyte * KB;
  const gigabyte = megabyte * KB;

  if (bytes >= 0 && bytes < kilobyte) {
    return bytes + " B";
  } else if (bytes >= kilobyte && bytes < megabyte) {
    return (bytes / kilobyte)?.toFixed(precision) + " KB";
  } else if (bytes >= megabyte && bytes < gigabyte) {
    return (bytes / megabyte)?.toFixed(precision) + " MB";
  } else if (bytes >= gigabyte) {
    return (bytes / gigabyte)?.toFixed(precision) + " GB";
  } else {
    return bytes + " B";
  }
}
// Systems Speed calculations.
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = KB;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

// IIS graph bits conversion
export function bytes_to_bitspersec(value) {
  let convertValue = value * 8;

  const kilobyte = KB;
  const megabyte = kilobyte * KB;
  const gigabyte = megabyte * KB;

  if (convertValue >= 0 && convertValue < kilobyte) {
    return convertValue + " b/s";
  } else if (convertValue >= kilobyte && convertValue < megabyte) {
    return (convertValue / kilobyte / 60)?.toFixed(2) + " Kbps";
  } else if (convertValue >= megabyte && convertValue < gigabyte) {
    return (convertValue / megabyte / 60)?.toFixed(2) + " Mbps";
  } else if (convertValue >= gigabyte) {
    return (convertValue / gigabyte / 60)?.toFixed(2) + " Gbps";
  }
}
