//Refer MUI doc: https://mui.com/material-ui/react-select/
import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MUISelect from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MUIMenuItem from "@mui/material/MenuItem";

export function Select({ label, minWidth, width, sx, isDisabled, ...others }) {
  return (
    <FormControl
      disabled={isDisabled}
      sx={{
        "&.MuiFormControl-root": {
          minWidth: minWidth || width || 120,
        },
        ...sx,
      }}
      size="small"
    >
      <InputLabel>{label}</InputLabel>
      <MUISelect
        // sx={{
        //   height: "35px",
        //   fontSize: "0.9rem",
        // }}
        label={label}
        {...others}
      />
    </FormControl>
  );
}
export function MenuItem({ value, children, ...others }) {
  return (
    <MUIMenuItem
      value={value}
      // sx={{
      //   "&.MuiMenuItem-root ": { fontSize: "0.75rem", p: "5px 6px" },
      //   "#menu- .MuiList-root": { display: "flex", flexDirection: "column" },
      // }}
      {...others}
    >
      {children}
    </MUIMenuItem>
  );
}

export default Select;
