import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { datacenters } from "config/server";
import { axiosGet, getError } from "utils/axios-api.util";
import { logout } from "../actions";

const initialState = {
  list: [],
  selected: {
    id: "",
    location: "IN-MUM-WEST-1",
    u_location: "IN-MUM-WEST-1",
    cDate: "",
  },
  loading: false,
  error: false,
  errMessage: "",
};

export const fetchDatacenters = createAsyncThunk(
  "fetch-datacenters",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const res = await axiosGet(datacenters, token);
      const data = res.data?.message?.map((center) => ({
        id: center._id,
        location: center.data_center_display,
        u_location: center.data_center,
        cDate: center.cdate,
      }));

      return data;
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(errMsg);
      throw errMsg;
    }
  }
);
const datacenterSlice = createSlice({
  name: "datacenters",
  initialState,
  reducers: {
    setSelectedDatacenter(state, action) {
      return {
        ...state,
        selected: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDatacenters.pending, (state, action) => {
      return {
        list: [],
        selected: {
          id: "",
          location: "",
          u_location: "",
          cDate: "",
        },
        loading: true,
        error: false,
        errMessage: "",
      };
    });

    builder.addCase(fetchDatacenters.fulfilled, (state, action) => {
      return { ...state, loading: false, list: action.payload };
    });

    builder.addCase(fetchDatacenters.rejected, (state, action) => {
      const errMessage = action.payload
        ? action.payload.errorMessage
        : action.error.message;
      const newState = { ...state, error: true, loading: false, errMessage };
      return newState;
    });

    builder.addCase(logout.toString(), (state, action) => {
      return {
        ...state,
        loading: false,
        error: false,
        errMessage: "",
      };
    });
  },
});

export default datacenterSlice;
export const { setSelectedDatacenter } = datacenterSlice.actions;
