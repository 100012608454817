import {
  Box,
  InputAdornment,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { HelperText } from "pages/signup/SignUp";
import { useState } from "react";
import { FiMail } from "react-icons/fi";
import { MdInfoOutline } from "react-icons/md";
import Validator from "utils/validator";

function EmailInput(props) {
  const theme = useTheme();
  const [valid, setValidity] = useState(true);
  const [value, setValue] = useState("");

  function handleChange(e) {
    setValidity(true);
    const { value } = e.target;
    const isValid = new Validator(value).newEmail();
    setValidity(isValid);
    setValue(value.trim());

    if (props.onChange) {
      props.onChange(e, isValid);
    }
  }

  return (
    <TextField
      placeholder="Enter your email"
      id="noWhiteSpaceInput"
      pattern="^\S+$"
      {...props}
      onChange={handleChange}
      type="email"
      error={!valid}
      helperText={
        !valid && (
          <HelperText
            errMsg={"Please enter a valid email"}
            details={[
              "Domain should be either",
              "• com",
              "• in",
              "• co",
              "• org",
              "• net",
              "• us",
              "• tech",
            ]}
          />
        )
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FiMail />
          </InputAdornment>
        ),
      }}
      sx={{
        ".MuiFormHelperText-root.Mui-error": {
          color: `${theme.palette.error.main}!important`,
        },
      }}
    />
  );
}

export default EmailInput;
