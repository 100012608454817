import { toast } from "react-toastify";

export function toastUpdateSuccess(toast_id, render) {
  toast.dismiss(toast_id);
  toast.success(render);
}
export function toastUpdateInfo(toast_id, render) {
  toast.dismiss(toast_id);
  toast.info(render);
}
export function toastUpdateError(toast_id, render) {
  toast.dismiss(toast_id);
  toast.error(render);
}
