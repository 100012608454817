import React from "react";
import { styled } from "@mui/material/styles";
import MUIButton from "@mui/material/Button";
import { Box, CircularProgress } from "@mui/material";

const StyledButton = styled(MUIButton)(() => ({}));

function Button({ children, loading, loadingText, ...others }) {
  if (loading) {
    return (
      <StyledButton {...others} onClick={() => {}}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <CircularProgress sx={{}} size={20} color="inherit" />
          {loadingText || "In progress..."}
        </Box>
      </StyledButton>
    );
  }
  return <StyledButton {...others}>{children}</StyledButton>;
}

export default Button;
