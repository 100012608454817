import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosGet, getError } from "utils/axios-api.util";
import { bannerEndPoint } from "config/server";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const screenNames = {
  "/coc/dashboard": "Dashboard",
  "/coc/privilege/users": "Users",
  "/coc/manage/instance": "Instance",
  "/coc/manage/cloud_connect": "Cloud Connect",
  "/coc/manage/zoom_view/services": "Zoom View",
  "/coc/manage/zoom_view/graphs": "Zoom View",
  "/coc/manage/zoom_view/internet": "Zoom View",
  "/coc/manage/lama": "LAMA",
  "/coc/manage/firewall": "Firewall",
  "/coc/manage/setting": "Setting",
  "/coc/manage/backup/overview": "Backup",
  "/coc/manage/backup/jobs": "Backup",
  "/coc/manage/backup/configuration": "Backup",
  "/coc/billing/payment_history": "Payment History",
  "/coc/billing/invoices": "Invoices",
  "/coc/reports/bandwidth": "Bandwidth",
  "/coc/reports/lama_report": "Lama Report",
  "/coc/reports/ip_tracking": "IP Tracking",
  "/coc/reports/top_apps": "Top Apps",
  "/coc/reports/geo_traffic": "Geo Traffic",
  "/coc/reports/summary_report": "Summary Report",
  "/coc/profile": "Profile",
  "/coc/docs/getting_started": "Getting Started",
  "/coc/docs/installation": "Installation",
};

export const getBannerInfo = createAsyncThunk(
  "get-banner-info",
  async (location, thunkApi) => {
    const { pathname } = location;
    try {
      const state = thunkApi.getState();
      const token = state?.user?.token ?? "";
      const routeToScreenName = screenNames[pathname];

      const BANNER_INFO_URL = `${bannerEndPoint}/list?screen_name=${routeToScreenName}&is_display=true&one=true`;
      const res = await axiosGet(BANNER_INFO_URL, token);
      return res?.data?.message ?? [];
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(err);
      toast.error(errMsg ?? "");
      throw errMsg;
    }
  }
);

const bannerInfo = createSlice({
  name: "bannerInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBannerInfo.pending, (state) => {
        state.isLoading = true;
        state.data = null;
        state.error = null;
      })
      .addCase(getBannerInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getBannerInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.data = null;
        state.error = action.error.message;
      });
  },
});

export default bannerInfo.reducer;
