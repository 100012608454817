import React from "react";
import MUITextfield from "@mui/material/TextField";
import { red } from "@mui/material/colors";

export function TextField(props) {
  return (
    <>
      <MUITextfield
        {...props}
        size="small"
        sx={{
          ...props.sx,
        }}
        FormHelperTextProps={{ sx: { color: `${red[500]}!important` } }}
      />
    </>
  );
}

export default TextField;
