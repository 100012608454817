export const domain = process.env.REACT_APP_DOMAIN;

export const zTalk = "https://zybisys.com/z-talk";

//link to partner application
export const partner_link = process.env.REACT_APP_PARTNERLINK;
// saas lama signup
export const LAMA_SIGNUP = `${domain}/saas/user/registration/basic`;
export const VERIFICATION_GST = `${domain}/third_party/gst/verification`;
export const ADDRESS_INFO = `${domain}/saas/user/registration/address`;
export const RESEND_OTP = (mobile) => `${domain}/saas/otp/mobile/${mobile}`;
export const RESEND_EMAIL_OTP = (email) => `${domain}/saas/otp/mail/${email}`;
export const OTP_VERIFICATION = (mobile, otp) =>
  `${domain}/saas/otp/mobile/${mobile}/${otp}`;
export const OTP_EMAIL_VERIFICATION = (email, otp) =>
  `${domain}/saas/otp/mail/${email}/${otp}`;
export const CUSTOMER_UUID_URL = (user_id) =>
  `${domain}/saas/document/download/${user_id}`;
export const formOptions = `${domain}/admin-api/cloud-connect/static/constants`;
// LOGIN APIs
export const login = `${domain}/users/login`;
export const oslogin = `${domain}/login/open-cloud`;
export const setNewPassword = `${domain}/admin/set-password`;
// RESET PASSWORD APIs
export const forgotPasswordEmail = (email) =>
  `${domain}/admin/forget-password-otp/${email}`;
export const forgotPasswordOTP = (otp, email) =>
  `${domain}/admin/forget-password/${otp}/${email}`;
export const resetPassword = `${domain}/users/reset-password`;
export const emailResetPassword = (token) =>
  `${domain}/users/link_expiry/${token}`;
// SIGN UP API
export const signup = `${domain}/coc/signup`;

// instanceIp datacenter
export const instanceIp = (datacenter) =>
  `${domain}/users/ip-list?datacenter=${datacenter}`;

// LAMA
export const lamaSetup = (env_type, datacenter) =>
  `${domain}/lama/api/coc/setting/customer/initial/${env_type}?datacenter=${datacenter}`;
export const lamaSetupUpdate = (env_type, datacenter) =>
  `${domain}/lama/api/coc/setting/customer/update-setting/${env_type}?datacenter=${datacenter}`;
export const lamaEnable = (env_type) =>
  `${domain}/lama/api/coc/setting/service/env/${env_type}`;
export const lamaOverview = (userId, datacenter, env_type) =>
  `${domain}/lama/api/coc/report/overview/exchange/details/${env_type}?user_id=${userId}&datacenter=${datacenter}`;

export const webErrorLog = (ip, limit) =>
  `${domain}/lama/api/coc/application/get_logs/${ip}?limit=${limit}`;
//Approve LAMA
export const approveApi = (env_type, datacenter) =>
  `${domain}/lama/api/coc/report/preview/acknowledgment/data/${env_type}?datacenter=${datacenter}`;
export const updateData = (env_type) =>
  `${domain}/lama/api/coc/setting/customer/activate/${env_type}`;
// Athontication
export const userValidation = (env_type, datacenter) =>
  `${domain}/lama/api/coc/setting/validate/credential/${env_type}?datacenter=${datacenter}`;

// lama exchange
export const exchangeSetting = (datacenter, env_type) =>
  `${domain}/lama/api/coc/report/customer/details/${env_type}?datacenter=${datacenter}`;

export const updateCustomer = (datacenter) =>
  `${domain}/lama/customer/save?datacenter=${datacenter}`;

// lama json
export const lamaJosnConfig = (user_id) =>
  `${domain}/lama/customer/download/log_store_machine_details?user_id=${user_id}`;

//lama update instance& metric
export const instanceData = (env_type) =>
  `${domain}/api/coc/report/customer/details/${env_type}`;

export const instanceSetting = (user_id, datacenter) =>
  `${domain}/lama/customer/setting?user_id=${user_id}&datacenter=${datacenter}`;

export const updateInstance = (datacenter) =>
  `${domain}/lama/customer/exchange?datacenter=${datacenter}`;

// lama OTP
export const otpRequest = (datacenter) =>
  `${domain}/lama/otp?datacenter=${datacenter}`;
export const unsubscribe = (uid) =>
  `${domain}/lama/api/coc/setting/delete/${uid}`;

export const otpSuccess = (otp, uid, datacenter) =>
  `${domain}/lama/validate/otp/${otp}?user_id=${uid}&datacenter=${datacenter}`;

export const Nse_Overview = (user_id, datacenter) =>
  `${domain}/lama/overview/nse?user_id=${user_id}&datacenter=${datacenter}`;
export const Bse_Overview = (user_id, datacenter) =>
  ` ${domain}/lama/overview/bse?user_id=${user_id}&datacenter=${datacenter}`;
export const mcx_Overview = (user_id, datacenter) =>
  `${domain}/lama/overview/mcx?user_id=${user_id}&datacenter=${datacenter}`;
export const ncdex_Overview = (user_id, datacenter) =>
  `${domain}/lama/overview/ncdex?user_id=${user_id}&datacenter=${datacenter}`;
export const exchange_log = (
  userid,
  to,
  from,
  log_type,
  exchange_id,
  datacenter,
  env_type
) =>
  `${domain}/lama/api/coc/report/datalog/exchange_log/${env_type}?user_id=${userid}&from=${from}&to=${to}&log=${log_type}&exchange=${exchange_id}&datacenter=${datacenter}`;
export const overviewCount = (userId, datacenter, env_type) =>
  `${domain}/lama/api/coc/report/overview/details/${env_type}?user_id=${userId}&datacenter=${datacenter}`;

export const activity_log = (userId, datacenter, env_type) =>
  `${domain}/lama/api/coc/report/datalog/activity_log/${env_type}?user_id=${userId}&datacenter=${datacenter}`;

// Vendor APIs
export const venorAccept = (token) =>
  `${domain}/users/vendor-accept-vm/${token}`;
export const venorReject = (token) =>
  `${domain}/users/vendor-decline-vm/${token}`;

// COMMON APIs
export const paynow = `${domain}/payment/paynow`;
export const search = (search_name, datacenter) =>
  `${domain}/users/search/${search_name}?datacenter=${datacenter}`;
export const feedback = (rating) => `${domain}/users/feedback/${rating}`;
export const bellNotification = `${domain}/users/bell-notification`;
export const bellNotificationUnseen = `${domain}/users/bell-notification-unseen`;
export const updateBellNotification = `${domain}/users/update-bell-notification-unseen`;

// DASHBOARD APIs
export const vm_instances = (datacenter) =>
  `${domain}/users/dashboard-vm?datacenter=${datacenter}`;
export const instance_bandwidth = (datacenter, publicIp) =>
  `${domain}/dashboard/bandwidth/${datacenter}/${publicIp}`;
export const cpu_and_ram = (datacenter) =>
  `${domain}/dashboard/cpu-ram/average/${datacenter}`;
export const updated_vm_instances = (uid) => `${domain}/users/user-vm/${uid}`;
export const vm_service_status = (datacenter) =>
  `${domain}/users/vm-status?datacenter=${datacenter}`;
export const ticket_count = `${domain}/users/ticket-count`;
export const ticket = `${domain}/users/ticket`;
export const updateTicket = (id) => `${domain}/users/ticket/${id}`;
export const vm_sheduled_task = (task, vmid, atime, flag) =>
  `${domain}/users/vm-schedule-task/${task}/${vmid}/${atime}?flag=${flag}`;
export const tagsAPI = `${domain}/users/vm-tags`;
export const outstanding = `${domain}/users/outstanding`;
export const ticketsCount = `${domain}/users/ticket-count`;
export const tagVm = (tag, datacenter) =>
  `${domain}/users/tag-vm/${tag}?datacenter=${datacenter}`;
export const cxoReports = (date, datacenter) =>
  `${domain}/document/cxo-report/${date}/${datacenter}`;
export const bandwidthApi = (datacenter) =>
  `${domain}/dashboard/bandwidth/${datacenter}`;
export const removeVendor = (vid, ip) =>
  `${domain}/users/remove-vendor/${vid}/${ip}`;
export const instanceName = (vmid) => `${domain}/users/vm-alias/${vmid}`;
export const addVendor = `${domain}/users/add-vendor`;
export const customersVendor = `${domain}/users/customer-vendors`;
export const customerVmInfo = (ip, datacenter) =>
  `${domain}/users/vm-info/${ip}?datacenter=${datacenter}`;
export const userVmListForVendor = (datacenter) =>
  `${domain}/users/power-on-vm?datacenter=${datacenter}`;
export const searchVendor = (searchname) =>
  `${domain}/users/vendor-search/${searchname}`;
export const assignTheVmVendor = (vid) =>
  `${domain}/users/vm-vendor-invite/${vid}`;
export const duplicacy = (datacenter) =>
  `${domain}/users/duplicacy?datacenter=${datacenter}`;

export const webConsole = (ip) => `${domain}/users/web-console-ticket/${ip}`;
export const instanceHistory = (datacenter, lan_ip, host_id) =>
  `${domain}/users/vm-history?datacenter=${datacenter}&lan_ip=${lan_ip}&host_id=${host_id}`;

// USERS APIs
export const subUsersList = `${domain}/users/sub-user-list`;
export const addSubUser = `${domain}/admin/sub-user`;

export const editSubUser = (sid) => `${domain}/admin/sub-user-profile/${sid}`;
export const updateSubUser = `${domain}/admin/update-sub-user?source=Primary User`;
export const verifyMailSubUser = (email) =>
  `${domain}/admin/user-verification-mail/${email}`;
export const deleteSubUser = `${domain}/admin/delete-sub-user?source=Primary User`;

export const confirmUserPassword = `${domain}/users/subuser-check-password`;
export const changePasswordSubUser = (uid) =>
  `${domain}/admin/userpassword/${uid}`;
export const logsOfSubUser = (uid) => `${domain}/admin/sub-user-changes/${uid}`;
export const rolesOfSubUser = (uid) => `${domain}/admin/sub-user-roles/${uid}`;
export const instancesOfSubUser = (uid, datacenter) =>
  `${domain}/users/user-vm/${uid}?datacenter=${datacenter}`;
export const postInstancesSubUser = (datacenter) =>
  `${domain}/users/user-vm?datacenter=${datacenter}`;

//DATACENTERS
export const datacenters = `${domain}/datacenter`;
export const datacenter_select = `${domain}/datacenter/customer`;

// LAMA APi
export const metric = (env) =>
  `${domain}/lama/api/coc/report/metric-stats-limit/${env}`;
export const postMetric = (env) =>
  `${domain}/lama/api/coc/setting/metric-stats-limit/${env}`;
export const overviwData = `${domain}/api/config/overview`;
export const logDetails = (
  type,
  userId,
  from,
  to,
  log,
  exchange,
  datacenter,
  groupId
) =>
  `${domain}/lama/api/coc/report/datalog/exchange_log/details/${type}?user_id=${userId}&from=${from}&to=${to}&log=${log}&exchange=${exchange}&datacenter=${datacenter}&group=${groupId}`;

// INSTANCES APIs
export const instancesLists = (datacenter) =>
  `${domain}/users/monitor?datacenter=${datacenter}`;
export const vmNotifications = (skip, limit, ip) =>
  `${domain}/users/vm-logs/${ip}/0/10`; // Instance Notifications;
export const vmInstanceStatus = (datacenter) =>
  `${domain}/users/vm-status?datacenter=${datacenter}`;

// BACKUP 2.0
export const backup_status = (datacenter) =>
  `${domain}/backup/zcs-status?datacenter=${datacenter}`;
export const backup_overview = (datacenter, poolid) =>
  `${domain}/backup/overview/${poolid}?datacenter=${datacenter}`;
export const backup_agents = (datacenter, poolid) =>
  `${domain}/backup/agents/${poolid}?datacenter=${datacenter}`;
export const backup_start_job = (datacenter) =>
  `${domain}/backup/start-job?datacenter=${datacenter}`;
export const backup_delete_job = (datacenter, jobName) =>
  `${domain}/backup/job/${jobName}?datacenter=${datacenter}`;
export const backup_stop_job = (datacenter) =>
  `${domain}/backup/stop-job?datacenter=${datacenter}`;
export const backup_assign_agent = `${domain}/backup/add-agents`;
export const backup_remove_agent_otp = `${domain}/backup/remove-agent-otp`;
export const backup_remove_agent = (otp, otpToken) =>
  `${domain}/backup/remove-agent/${otp}/${otpToken}`;
export const backup_contract = (datacenter) =>
  `${domain}/backup/contract?datacenter=${datacenter}`;
export const backup_job_resource = (type, type_name, datacenter) =>
  `${domain}/backup/resource/${type}/${type_name}?datacenter=${datacenter}`;
export const backup_delete_fs_schedule = ({ fileset, schedule }, datacenter) =>
  `${domain}/backup/fs-schedule?datacenter=${datacenter}${
    Boolean(fileset) ? `&fileset_name=${fileset}` : ""
  }${Boolean(schedule) ? `&schedule_name=${schedule}` : ""}
    `;
export const job_details = (poolid, clientid, job_name, datacenter) =>
  `${domain}/backup/job-view/${poolid}/${clientid}/${job_name}?datacenter=${datacenter}`;
export const job_logs = (jobId, datacenter) =>
  `${domain}/backup/job-log/${jobId}/?datacenter=${datacenter}`;

export const vmStartOtp = (datacenter, ip, action) =>
  `${domain}/users/vm-action-otp?datacenter=${datacenter}&ip=${ip}&action=${action}`;
export const vmRestart = `${domain}/users/vm-action`;

// BACULA API (BACKUP)
export const datacenterPool = (datacenter) =>
  `${domain}/backup/pool/${datacenter}`;
export const pool_vm = (datacenter, poolId) =>
  `${domain}/backup/pool-vm/${datacenter}/${poolId}`;
export const vm_ls = (datacenter, clientId, path) =>
  ` ${domain}/backup/vm-ls/${datacenter}/${clientId}?path=${path}`;

export const vm_fileset = (datacenter, clientid) =>
  `${domain}/backup/vm-fileset/${datacenter}/${clientid}`;
export const update_vm_fileset = (datacenter, clientid) =>
  `${domain}/backup/update-vm-fileset/${datacenter}/${clientid}`;
export const pool_filesets = (datacenter, poolid) =>
  `${domain}/backup/pool-fileset/${datacenter}/${poolid}`;
export const vm_job_pre = (datacenter) =>
  `${domain}/backup/vm-job-pre/${datacenter}`;
export const vm_job = (datacenter, poolid, clientid, action) =>
  `${domain}/backup/vm-job/${datacenter}/${poolid}/${clientid}${
    action ? `?action=${action}` : ""
  }`;
export const update_vm_job = ({ datacenter, poolid, clientid, job_name }) =>
  `${domain}/backup/update-vm-job/${datacenter}/${poolid}/${clientid}?job_name=${job_name}`;
export const job_jobhistory = ({ datacenter, job_name, poolid, clientid }) =>
  `${domain}/backup/job-jobhistory/${datacenter}/${poolid}/${clientid}?job_name=${job_name}`;

export const backup_cost = (datacenter) =>
  `${domain}/backup/price?datacenter=${datacenter}`;
export const job_cache = (datacenter, jobId) =>
  `${domain}/backup/job-cache?datacenter=${datacenter}&jobid=${jobId}`;
export const job_dir_files = (datacenter, jobId, path = "") =>
  `${domain}/backup/job-dir-files?jobid=${jobId}&path=${path}&datacenter=${datacenter}`;
export const job_restore = (datacenter) =>
  `${domain}/backup/restore-job?datacenter=${datacenter}`;
export const job_bvfs_restore = (id, datacenter) =>
  `${domain}/backup/job-bvfs-restore/${id}?datacenter=${datacenter}`;
export const restore_log = (datacenter, jobId) =>
  `${domain}/backup/job/${jobId}?datacenter=${datacenter}`;
export const schedule_backup = (datacenter, clientid) =>
  `${domain}/backup/schedule/${datacenter}/${clientid}`;
export const restore_progress = (jobId, restoreId, datacenter) =>
  `${domain}/backup/progress/${jobId}/${restoreId}?datacenter=${datacenter}`;
export const job_files = (datacenter, jobId) =>
  `${domain}/backup/file-size/${datacenter}/${jobId}`;

// SETTING APIs
export const dailyReportSetting = `${domain}/users/daily-report-setting`;
export const notifyDailyReport = `${domain}/users/email-report-setting`;
export const cloudConnectReport = `${domain}/users/email-exchange-login-error-setting`;
// CLOUD CONNECT
export const cloudconnectHostData = (ip, from, to, datacenter, purpose) =>
  `${domain}/cloud-connect/top-host/${ip}/${from}/${to}?datacenter=${datacenter}&purpose=${purpose}`;
export const cloudconnectAppData = (ip, from, to, datacenter, purpose) =>
  `${domain}/cloud-connect/top-apps/${ip}/${from}/${to}?datacenter=${datacenter}&purpose=${purpose}`;
export const deviceStatus = (data) =>
  `${domain}/cloud-connect/ram-cpu-disk/${data.ip}/${data.from}/${data.to}?datacenter=${data.datacenter}&purpose=${data.purpose}`;

export const cloudConnectOverview = (datacenter) =>
  `${domain}/cloud-connect/overview?datacenter=${datacenter}`;

export const cloudConnect_segmentLogIn = (brokerId, linkType) =>
  `${domain}/cloud-connect/overview/login?broker_id=${brokerId}&link_type=${linkType}`;
export const check_router_ip = `${domain}/cloud-connect/check-router-ip `;
export const check_broker_id = `${domain}/cloud-connect/check-brokerid`;
export const check_mac_address = `${domain}/cloud-connect/get-router-mac`;
export const cloudConnectOverview_Mess_per_sec = (brokerId, linkType) =>
  `${domain}/cloud-connect/overview/msg-per-sec?broker_id=${brokerId}&link_type=${linkType}`;

export const cloudConnectOverview_latency = (brokerId, linkType) =>
  `${domain}/cloud-connect/overview/latency?broker_id=${brokerId}&link_type=${linkType}`;
export const cloudConnect_latency = (segment, from, to, ip) =>
  `${domain}/cloud-connect/nse-${segment}-msg-latency${
    Boolean(from && to) ? `?from=${from}&to=${to}&ip=${ip}` : ""
  }`;
export const cloudConnect_Mess_per_sec = (segment, from, to, ip) =>
  `${domain}/cloud-connect/nse-${segment}-msg-per-sec${
    Boolean(from && to) ? `?from=${from}&to=${to}&ip=${ip}` : ""
  }`;
export const addCloudConnect = `${domain}/cloud-connect`;
export const updateUnpaired_CloudConnect = (cid) =>
  `${domain}/cloud-connect/${cid}`;
export const updatePaired_CloudConnect = (cid) =>
  `${domain}/cloud-connect/pair/${cid}`;
export const deleteCloudConnect = (cid, datacenter) =>
  `${domain}/users/cloud-connect/${cid}?datacenter=${datacenter}`;
export const allCloudConnect = (datacenter) =>
  `${domain}/users/cloud-connect?datacenter=${datacenter}`;
export const cloudConnectProvider = (datacenter) =>
  `${domain}/users/cloud-connect-provider?datacenter=${datacenter}`;
export const editCloudConnect = (id, datacenter) =>
  `${domain}/users/cloud-connect/${id}?datacenter=${datacenter}`;
export const cloudConnectChart = (mac, from, to, datacenter) =>
  `${domain}/users/counter_item_mac/${mac}/${from}/${to}?datacenter=${datacenter}`;
export const cloudConnectInternalhost = (ip, from, to, datacenter) =>
  `${domain}/users/cloud-connect-internal-host/${ip}/${from}/${to}?datacenter=${datacenter}`;
export const cloudConnectInt_Ext = (ip, from, to, datacenter) =>
  `${domain}/users/cloud-connect-internal-to-external/${ip}/${from}/${to}?datacenter=${datacenter}`;
export const cloudConnectIntHst = (ip, from, to, datacenter) =>
  `${domain}/users/cloud-connect-internalhost/${ip}/${from}/${to}?datacenter=${datacenter}`;
export const cloudConnectMulticast = (ip, from, to, datacenter) =>
  `${domain}/users/cloud-connect-multicast/${ip}/${from}/${to}?datacenter=${datacenter}`;
export const cloudConnectLogs = (datacenter) =>
  `${domain}/users/cloud-connect-logs?datacenter=${datacenter}`;

export const cloudConnectPairLogs = (selectedPairValue, pairTimeInterval) =>
  `${domain}/cloud-connect/logs/${selectedPairValue}?date=${pairTimeInterval}`;

export const pairCloudConnect = (datacenter) =>
  `${domain}/users/cloud-connect-pair?datacenter=${datacenter}`;
export const unpairCloudConnect = (id, datacenter) =>
  `${domain}/users/cloud-connect-unpair/${id}?datacenter=${datacenter}`;

export const cloudConnect_infra_logs = (pairname, date) =>
  `${domain}/cloud-connect/logs/network/${pairname}?date=${date}`;
export const cloudConnect_application_logs = (datacenter, linkType, date) =>
  `${domain}/cloud-connect/logs/app/${linkType}?datacenter=${datacenter}&date=${date}`;

export const cloudConfigImport = `${domain}/cloud-connect/import/json`;
export const cloudConfigExport = `${domain}/cloud-connect/export/json`;

// ZOOM VIEW
export const zvInstances = (datacenter) =>
  `${domain}/users/monitor/vm?datacenter=${datacenter}`;
export const zvServices = (ip, datacenter) =>
  `${domain}/users/vm/status/${ip}?datacenter=${datacenter}`;
export const createZVServiceAlert = `${domain}/users/monitor/stop-alert`;
export const getZVServiceAlert = (ip) =>
  `${domain}/users/monitor/stop-alert/${ip}`;

// Graphs
export const ramChart = (ip, from, to) =>
  `${domain}/users/graph/ram/${ip}/${from}/${to}`;
export const cpuChart = (ip, from, to) =>
  `${domain}/users/graph/cpu/${ip}/${from}/${to}`;
export const cpuLoadChart = (ip, from, to) =>
  `${domain}/users/graph/cpu-load/${ip}/${from}/${to}`;
export const hddChart = (ip, from, to) =>
  `${domain}/users/graph/hdd/${ip}/${from}/${to}`;
export const diskIOSummaryChart = (ip, from, to) =>
  `${domain}/users/graph/disk-io-summary/${ip}/${from}/${to}`;
export const InterfacesChart = (ip, from, to) =>
  `${domain}/users/graph/interfaces/${ip}/${from}/${to}`;
export const tcpConnectionsChart = (ip, from, to) =>
  `${domain}/users/graph/tcp-connection/${ip}/${from}/${to}`;
export const noOfThreadsChart = (ip, from, to) =>
  `${domain}/users/graph/no-of-thread/${ip}/${from}/${to}`;

// IIS Graphs
export const IIS_Instances = (item, ip, from, to) =>
  `${domain}/users/graph/iis${item}/${ip}/${from}/${to}`;

// Internet
export const internetLineGraph = (ip, from, to, datacenter) =>
  `${domain}/users/counter_item/${ip}/${from}/${to}?datacenter=${datacenter}`;
export const zvExternalIp = (ip, from, to, datacenter) =>
  `${domain}/users/ip-external-ip/${ip}/${from}/${to}?datacenter=${datacenter}`;
export const zvIpApps = (ip, from, to, datacenter) =>
  `${domain}/users/ip-apps-list/${ip}/${from}/${to}?datacenter=${datacenter}`;
export const zvIpCities = (ip, from, to, datacenter) =>
  `${domain}/users/ip-geo-list/${ip}/${from}/${to}?datacenter=${datacenter}`;
export const zvIpConnectionList = (ip, from, to, datacenter) =>
  `${domain}/users/ip-connection-ip/${ip}/${from}/${to}?datacenter=${datacenter}`;
export const zvIpSessionRequest = (ip, from, to, datacenter) =>
  `${domain}/users/ip-session-request/${ip}/${from}/${to}?datacenter=${datacenter}`;
export const csvDownload = (ip, from, to, datacenter) =>
  `${domain}/download/download-internet/${ip}/${from}/${to}?datacenter=${datacenter}`;
// TASK & BACKUPS
export const zvTasks = (ip) => `${domain}/users/vm-schedule-task/${ip}`;
export const zvBackup = (ip) => `${domain}/users/vm-backup/${ip}?limit=0`;

// BILLING APIs
export const invoicesList = `${domain}/users/invoices`;
export const paymentHistory = `${domain}/payment/history`;
export const bandwidthUsage = (month, year, datacenter) =>
  `${domain}/users/bandwidth_report/${month}/${year}?datacenter=${datacenter}`;
export const bandwidthUsageSetting = (month, year, datacenter) =>
  `${domain}/users/bandwidth_report_setting/${month}/${year}?datacenter=${datacenter}`;

// REPORTS APIs
export const ipData_BANDWIDTH = (from, to, datacenter) =>
  `${domain}/users/ip-bill-list/${from}/${to}?datacenter=${datacenter}`; // BANDWIDTH
export const lamaReportData = (userId, from, to, datacenter) =>
  `${domain}/lama/api/coc/assets/bytes_and_status/${userId}?from=${from}&to=${to}&datacenter=${datacenter}`;

export const External_IP_TRACKING = (from, to, datacenter) =>
  `${domain}/users/external-ip/${from}/${to}?datacenter=${datacenter}`; // IP TRACKING
export const ipExternal_IP_TRACKING = (from, to, ip, datacenter) =>
  `${domain}/users/external-ip/${from}/${to}?ip=${ip}&datacenter=${datacenter}`; // IP TRACKING WITH IP

export const Apps_TOP_APPS = (from, to, datacenter) =>
  `${domain}/users/apps-list/${from}/${to}?datacenter=${datacenter}`; // TOP APPS
export const ipApps_TOP_APPS = (from, to, ip, datacenter) =>
  `${domain}/users/apps-list/${from}/${to}?ip=${ip}&datacenter=${datacenter}`; // TOP APPS WITH IP

export const Cities_GEO_TRAFFIC = (from, to, datacenter) =>
  `${domain}/users/geo-list/${from}/${to}?datacenter=${datacenter}`; // GEO TRAFFIC
export const ipCities_GEO_TRAFFIC = (from, to, ip, datacenter) =>
  `${domain}/users/geo-list/${from}/${to}?ip=${ip}&datacenter=${datacenter}`; // GEO TRAFFIC WITH IP

export const ip_report = (from, to, datacenter) =>
  `${domain}/users/ip-report/${from}/${to}?datacenter=${datacenter}`; // IP TRACKING
export const ip_report_ip = (from, to, ip, datacenter) =>
  `${domain}/users/ip-report/${from}/${to}?ip=${ip}&datacenter=${datacenter}`; // IP TRACKING WITH IP

export const ip_track_csv_all = (from, to, datacenter) =>
  `${domain}/download/download-ip-tracking/${from}/${to}?datacenter=${datacenter}`;
export const top_apps_csv_all = (from, to, datacenter) =>
  `${domain}/download/download-top-apps/${from}/${to}?datacenter=${datacenter}`;
export const geo_traffic_csv_all = (from, to, datacenter) =>
  `${domain}/download/download-geo-traffic/${from}/${to}?datacenter=${datacenter}`;

// USER PROFILE
export const userProfile = `${domain}/users/profile`;
export const updatePassword = `${domain}/users/update-password`;
export const updateName = `${domain}/users/update-name`;
export const uploadImage = `${domain}/upload/customer/profile-image`;

// firewall
export const firewallInstances = (datacenter) =>
  `${domain}/users/vm?datacenter=${datacenter}`;
export const firewallIncList = (datacenter) =>
  `${domain}/users/firewall/incoming?datacenter=${datacenter}`;
export const firewallOutList = (datacenter) =>
  `${domain}/users/firewall/outgoing?datacenter=${datacenter}`;
export const firewall_unused_ports = (datacenter) =>
  `${domain}/users/firewall/port/not_in_use?datacenter=${datacenter}`;

// Banner
export const bannerEndPoint = `${domain}/users/banner`;
