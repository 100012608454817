import { lazy } from "react";

// AUTH
const Auth = lazy(() => import("pages/Login"));
const ForgotPassword = lazy(() => import("pages/Forgot-password"));
const ResetPassword = lazy(() => import("pages/Reset-password"));
const SetPassword = lazy(() => import("pages/Set-password"));
const VerifyEmail = lazy(() => import("pages/Verify-email"));
const Logout = lazy(() => import("pages/Logout"));

// DASHBOARD
const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"));
const AssignPartner = lazy(() => import("pages/Dashboard/AssignPartner"));

// PRIVILEGE
const Users = lazy(() => import("pages/Privilege/Users"));

// MANAGE
const Instance = lazy(() => import("pages/Manage/Instance.js"));
const ZoomView = lazy(() => import("pages/Manage/Zoomview"));
const CloudConnect = lazy(() => import("pages/Manage/CloudConnect"));
const Settings = lazy(() => import("pages/Manage/Setting"));
const Firewall = lazy(() => import("pages/Manage/Firewall"));
const Lama = lazy(() => import("pages/Manage/Lama"));
const BackUp = lazy(() => import("pages/Manage/Backup"));
const Jobhistory = lazy(() => import("pages/Manage/Backup/Job-history"));
const BackupPaymentSuccess = lazy(() =>
  import("pages/Billing/Backup-payment/payment-success")
);
const BackupPaymentFailed = lazy(() =>
  import("pages/Billing/Backup-payment/payment-failed")
);
const Plan = lazy(() => import("pages/Billing/Plan/index"));
const BackupPlan = lazy(() => import("pages/Billing/Plan/Backup-plan"));
const BackupPlanDetail = lazy(() =>
  import("pages/Billing/Plan/Backup-plan-detail")
);

// BILLINGS
const Invoices = lazy(() => import("pages/Billing/Invoices"));
const PaymentHistory = lazy(() => import("pages/Billing/Payment-history"));

// REPORTS
const Bandwidth = lazy(() => import("pages/Reports/Bandwidth"));
const LamaReport = lazy(() => import("pages/Reports/LamaReport"));
const IPTracking = lazy(() => import("pages/Reports/IP-tracking"));
const TopApp = lazy(() => import("pages/Reports/TopApp"));
const GeoTraffics = lazy(() => import("pages/Reports/GeoTraffics"));
const SummaryReport = lazy(() => import("pages/Reports/SummaryReport"));

// PROFILE
const UserProfile = lazy(() => import("pages/Profile"));

// DOCS
const GettingStarted = lazy(() => import("pages/Docs/Getting-started"));
const Installation = lazy(() => import("pages/Docs/Installation"));

// OTHERS
const PageNotFound = lazy(() => import("pages/404"));

export {
  Auth,
  ForgotPassword,
  ResetPassword,
  SetPassword,
  VerifyEmail,
  Logout,
  Dashboard,
  AssignPartner,
  Instance,
  ZoomView,
  CloudConnect,
  Settings,
  Firewall,
  Lama,
  BackUp,
  Invoices,
  PaymentHistory,
  Jobhistory,
  BackupPaymentSuccess,
  BackupPaymentFailed,
  Plan,
  BackupPlan,
  BackupPlanDetail,
  Users,
  Bandwidth,
  LamaReport,
  IPTracking,
  TopApp,
  GeoTraffics,
  SummaryReport,
  UserProfile,
  GettingStarted,
  Installation,
  PageNotFound,
};
