import CryptoJS from "crypto-js";
import moment from "moment";

//----------- CONTANTS ---------------
const keySize = 256;
const iterations = 100;

export function getUrlSearch(value) {
  const searchParams = new URLSearchParams(value);
  const search_values = {};
  for (const [key, value] of searchParams) {
    search_values[key] = value;
  }
  return search_values;
}

//================ time format ================
//if time < 24hrs => 2hr ago, if time > 24hrs => 12 May  2022, 12: 30 PM
export function getTimeData(timestamp) {
  const past = new Date(timestamp);
  const now = Date.now();
  const time_diff = now - past;
  const isNew = time_diff < 10000;
  const time_text =
    time_diff <= 24 * 60 * 60 * 1000
      ? moment(now).to(past)
      : moment(past).format("DD MMM, YYYY HH:mm:ss");

  return { time_text, isNew };
}

// Decryption and encryption codes;
export function encodeStrBase64(msg, pass) {
  let salt = CryptoJS.lib.WordArray.random(128 / 8);

  let key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  let iv = CryptoJS.lib.WordArray.random(128 / 8);

  let encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });

  // salt, iv will be hex 32 in length
  // append them to the ciphertext for use  in decryption
  let transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  return transitmessage;
}
//==================== To decode base64 string =============
//Token are encoded before storing it in redux store and decoded when sending it to server.
export function decodeStrBase64(transitmessage, pass) {
  let salt = CryptoJS.enc.Hex.parse(transitmessage?.substr(0, 32));
  let iv = CryptoJS.enc.Hex.parse(transitmessage?.substr(32, 32));
  let encrypted = transitmessage.substring(64);

  let key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  let decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

//================ Bytes to GB =============
//when small byte size is converted to GB it gives nearly to 0, for eg: byte less than 10,000k converted to GB gives nearly 0 GB which is not true so that's why we convert that into MB, same goes with converstion of byte to MB if it gives nearly 0 MB it's better to return bytes in bytes
export function formatBytesToGB(byte) {
  //new one with 1000

  const kb = 1000;

  if (byte < 1000) {
    return { num: byte, unit: "b", str: `${byte} B` };
  }
  if (byte >= 1000 && byte < 1000000) {
    const inKB = byte / Math.pow(kb, 1);
    return { num: inKB, unit: "Kb", str: `${inKB.toFixed(2)} KB` };
  }
  if (byte >= 1000000 && byte < 1000000000) {
    const inMB = byte / Math.pow(kb, 2);
    return { num: inMB, unit: "Mb", str: `${inMB.toFixed(2)} MB` };
  }
  if (byte >= 1000000000) {
    const inGB = byte / Math.pow(kb, 3);
    return { num: inGB, unit: "Gb", str: `${inGB.toFixed(2)} GB` };
  }
}

export function formatBytes(byte) {
  //new one with 1024

  const kb = 1024;

  if (byte < 1024) {
    return { num: byte, unit: "b", str: `${byte} B` };
  }
  if (byte >= 1024 && byte < 1048576) {
    const inKB = byte / Math.pow(kb, 1);
    return { num: inKB, unit: "Kb", str: `${inKB.toFixed(2)} KB` };
  }
  if (byte >= 1048576 && byte < 1073741824) {
    const inMB = byte / Math.pow(kb, 2);
    return { num: inMB, unit: "Mb", str: `${inMB.toFixed(2)} MB` };
  }
  if (byte >= 1073741824) {
    const inGB = byte / Math.pow(kb, 3);
    return { num: inGB, unit: "Gb", str: `${inGB.toFixed(2)} GB` };
  }
}

export function validatePairName(value) {
  const regEx = new RegExp(/^[A-Za-z][A-Za-z0-9-]{6,29}$/);
  const isValid = regEx.test(value);
  if (!value) {
    return { error: true, errMessage: "Required field" };
  }
  if (isValid) {
    return { error: !isValid, errMessage: "" };
  }
  return {
    error: !isValid,
    errMessage:
      "Invalid input,require {7-30} characters & allowed characters: A-Z,a-z,0-9,- and starts with Alphabet ",
  };
}
