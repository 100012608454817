import { Box, Button, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

const OtpAuth = ({ onChange, onReSendOTP, onTimeOut }) => {
  const [otp, setOtp] = useState("");
  const [startTimer, setStartTimer] = useState(true);
  const [timeLeft, setTimeLeft] = useState(299);

  function handleChange(value) {
    setOtp(value);
    if (onChange) {
      onChange(value);
    }
  }

  async function handleResend() {
    setStartTimer(false);
    await onReSendOTP();
    setTimeLeft(299);
    setStartTimer(true);
  }

  useEffect(() => {
    let timer;

    if (startTimer && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [startTimer, onTimeOut, timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <Box sx={{ width: "fit-content", margin: "0 auto" }}>
      <OtpInput
        value={otp}
        inputType="tel"
        isInputNum="tel"
        onChange={handleChange}
        numInputs={6}
        separator={<Box component="span" sx={{ margin: "0 5px" }}></Box>}
        shouldAutoFocus={true}
        inputStyle={{
          margin: "10px 5px",
          padding: "10px",
          border: "1px solid #132739",
          borderRadius: "6px",
          fontSize: "18px",
          width: "50px",
          backgroundColor: "transparent",
        }}
        focusStyle={{ border: `2px solid ${blue[500]}` }}
        renderInput={(props) => {
          return <input {...props} />;
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button size="small" onClick={handleResend}>
          Resend OTP
        </Button>
        <Typography variant="body2" sx={{ color: "yellow" }}>
          {`${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
            2,
            "0"
          )}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default OtpAuth;
