import { Box } from "@mui/material";
import { Button } from "components/features/UI/Inputs";
import OtpAuth from "components/features/auth/otp-auth";
import { useState } from "react";

export default function OtpMobileVerification({
  handleResendOTP,
  phone,
  handleSubmitOtp,
  otpVerified,
}) {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "60vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "32px",
        }}
      >
        <Box
          component="p"
          sx={{
            textAlign: "center",
            fontSize: "22px",
            fontWeight: "600",
            textAlignLast: "center",
            letterSpacing: "0.1px",
            marginBottom: "13px",
          }}
        >
          Verify Your Mobile with a Single Step
        </Box>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            component={"p"}
            sx={{
              width: "70%",
              maxWidth: "1000px",
              fontSize: "16px",
              textAlign: "center",
              fontWeight: "400",
              lineHeight: "25px",
              color: "#8D8B8B",
              marginBottom: "35px",
            }}
          >
            We're almost there! To ensure the security of your account, please
            enter the one-time password (OTP) sent to your registered mobile
            address. This extra layer of verification helps us safeguard your
            information and guarantees a smooth and protected experience across
            our platform.
          </Box>
        </Box>

        <GetOTP
          email={phone}
          onResendOtp={handleResendOTP}
          sending={otpVerified.loading}
          onSubmit={handleSubmitOtp}
          otpVerified={otpVerified}
        />
      </Box>
    </Box>
  );
}

function GetOTP({ onResendOtp, onSubmit, sending }) {
  const [otp, setOtp] = useState("");

  function handleOTP(otp) {
    setOtp(otp);
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "12px",
      }}
    >
      <OtpAuth onChange={handleOTP} onReSendOTP={onResendOtp} />
      <Button
        variant="contained"
        sx={{ marginTop: 1, width: "180px" }}
        fullWidth
        disabled={otp.length !== 6}
        loading={sending}
        type="submit"
        onClick={(e) => onSubmit(e, otp)}
      >
        Mobile Verify
      </Button>
    </Box>
  );
}
