import { createTheme } from "@mui/material/styles";

import {
  blue,
  grey,
  green,
  red,
  yellow,
  orange,
  amber,
} from "@mui/material/colors";

export const palette = {
  primary: {
    main: "#2F87FF",
    light: "#66a7ff",
    shadow: "#ceebfa",
  },
  secondary: {
    light: "#66bb6a",
    main: "#46db49",
    dark: "#47b34c",
  },
  widget: {
    info: blue["A400"],
    success: "#4db6ac",
    warn: amber[800],
    error: red[400],
  },
  info: {
    main: blue[600],
  },
  warn: {
    main: amber[700],
  },
  error: {
    main: red["A700"],
  },
  success: {
    main: green["A700"],
  },
  grey: {
    main: grey[500],
    light: grey[300],
    ...grey,
  },
  yellow: {
    main: "#FFCA03",
    ...yellow,
  },
  orange: {
    main: orange[300],
    ...orange,
  },
  green: {
    main: green[500],
    ...green,
  },
  blue: {
    ...blue,
  },
  red: {
    main: red[500],
    ...red,
  },
};
export const theme = createTheme({
  palette,
  typography: {
    fontFamily: ["Poppins", "Helvetica", "Arial", "sans-serif"].join(","),
  },
});
