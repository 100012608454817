import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { exchangeSetting } from "config/server";
import { axiosGet, getError } from "utils/axios-api.util";
import { encodeStrBase64 } from "utils/util";

const initialState = {
  status: {
    isLoading: true,
    isError: false,
    data: {},
    error: "",
  },
};

//-------------------------------------STATUS-----------------------/
export const fetchLamaStatus = createAsyncThunk(
  "lama-status",
  async (envType, thunkApi) => {
    try {
      const state = thunkApi.getState();
      const token = state.user?.token;
      const datacenter = state.datacenters?.selected?.u_location;
      const res = await axiosGet(exchangeSetting(datacenter, envType), token);
      const { login_id, member_id, password, secret_key, user_id, ...rest } =
        res.data.data;

      return {
        login_id: encodeStrBase64(login_id, "VmUYLszbyV"),
        member_id: encodeStrBase64(member_id, "VmUYLszbyV"),
        password: encodeStrBase64(password, "VmUYLszbyV"),
        secret_key: encodeStrBase64(secret_key, "VmUYLszbyV"),
        ...rest,
      };
    } catch (err) {
      const errMsg = getError(err, thunkApi.dispatch);
      thunkApi.rejectWithValue(errMsg);
      throw errMsg;
    }
  }
);

const lamaSlice = createSlice({
  name: "lama",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //------------------------lamaStatus------------/
    builder.addCase(fetchLamaStatus.pending, (state, action) => {
      state.status.isLoading = true;
      state.status.isError = false;
      state.status.error = "";
      state.status.data = {};
    });
    builder.addCase(fetchLamaStatus.fulfilled, (state, action) => {
      state.status.data = action.payload;
      state.status.isLoading = false;
    });
    builder.addCase(fetchLamaStatus.rejected, (state, action) => {
      state.status.isLoading = false;
      state.status.isError = true;
      state.status.error = action.error;
    });
  },
});

export default lamaSlice;
